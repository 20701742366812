import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { getHeaders } from "../../utils/apiUtils";
import { BASE_URL } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../components/common/userFeedback/CustomAlert";
import { StartButton } from "../../components/ui/buttons/TableButtons";
import { APP_BAR_HEIGHT } from "../../components/layout/AppBar/AppBarComponent";
import { useAmpltiudeEventTrackingContext } from "../../contexts/AmplitudeTrackingContext";
import StyledDataGrid from "../../components/ui/dataViews/StyledDataGrid";

function HomeDashboard() {
  const navigate = useNavigate();
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [jobs, setJobs] = useState([]);
  // State for handling dialog open/close

  useEffect(() => {
    const fetchPipelines = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/pipelines/my-jobs`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setJobs(res.data.data);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchPipelines();
  }, []);

  const columns = [
    { field: "pipeline_job_id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    {
      field: "pipeline_name",
      headerName: "Pipeline Name",
      flex: 1,
      editable: false,
    },
    {
      field: "pipeline_priority",
      headerName: "Priority",
      flex: 1,
      editable: false,
    },
    {
      field: "pipeline_id",
      headerName: "Pipeline ID",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <StartButton
          action={() => {
            //todowp finish StartPipelineSession event adn add EndPipelineSession
            //trackEvent("StartPipelineSession", {
            //  toolName: "Aggregation",
            //  garmentId: garmentID,
            //  jobId: 17,
            //  skipped: false
            //});
            navigate(`jobs/${params.row.name}/${params.row.pipeline_job_id}`);
            // navigate(
            //   `jobs/style_manual_tagging_new/${params.row.pipeline_job_id}`
            // );
          }}
        />
      ),
    },
    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 0.5,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">My Jobs</Typography>
      </Box>

      <StyledDataGrid
        rows={jobs}
        columns={columns}
        getRowId={(row) => row.pipeline_job_id}
        pageSize={5}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default HomeDashboard;
