import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import AddToGroupDialog from "../../../components/features/UserGroups/AddToGroupDialog";
import CustomToolbar from "../../../components/common/toolbars/UserGridCustomToolbar";
import { useAttributeType } from "../../../contexts/AttributeTypeContext";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import { EditButton } from "../../../components/ui/buttons/TableButtons";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";

function AttributeDashboard() {
  const navigate = useNavigate();
  const { attributeType, attributeScreenConfig } = useAttributeType();

  const [attributes, setAttributes] = useState([]);
  // State for handling dialog open/close

  useEffect(() => {
    if (!attributeType) return;
    const fetchUsers = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/attribute-names/${attributeType}`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setAttributes(res.data.data);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchUsers();
  }, [attributeType]);

  const columns = [
    { field: "id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <EditButton onEdit={() => navigate(`create/${params.row.id}`)} />
      ),
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2">
          {attributeScreenConfig?.name} Dashboard
        </Typography>
        <GroupConfigurationNavigationWidget
          new_button_text={`New ${attributeScreenConfig?.name}`}
        />
      </Box>

      <StyledDataGrid
        rows={attributes}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default AttributeDashboard;
