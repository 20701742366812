import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Chip,
  Container,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import { attributeTypes } from "../../../config/attributeTypes";
import { useAttributeType } from "../../../contexts/AttributeTypeContext";
import AttributeSelectionAndConfiguration from "../../../components/features/AttributeManagement/AttributeSelectionAndConfiguration";
import AttributesDAG from "../../../components/features/AttributeManagement/AttributesDag";
import CustomLoadingModal from "../../../components/common/userFeedback/CustomLoadingModal";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";

const CreateAttributeGroupScreen = () => {
  const { id } = useParams(); // Retrieve the ID parameter from the URL
  const navigate = useNavigate();
  const attributeId = id;
  const { attributeType, attributeScreenConfig } = useAttributeType();
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    selectedAttributes: [],
  });
  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    console.log(attributes);
  }, [attributes]);

  useEffect(() => {
    if (attributeId && attributeType) {
      fetchImageClassGroupDetails(attributeId);
    }
  }, [attributeId, attributeType]);

  const fetchImageClassGroupDetails = async (id) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${BASE_URL}tools/admin/fetch-attribute-group/${attributeType}/${id}`,
        headers
      );
      const { name, description, selectedAttributes } = response.data;
      setFormData({
        name,
        description,
      });
      setAttributes(selectedAttributes);
    } catch (error) {
      showAlert(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setSaving(true);
    e.preventDefault();
    const submissionData = {
      ...formData,
      selectedAttributes: attributes,
    };
    console.log(submissionData);

    try {
      const headers = await getHeaders(); // Assuming getHeaders is a function that asynchronously returns an object with headers

      axios
        .post(
          `${BASE_URL}tools/admin/create-attribute-group/${attributeType}${
            attributeId ? `/${attributeId}` : ""
          }`,
          submissionData,
          headers
        )
        .then((response) => {
          setSaving(false);
          // Optionally, you might want to redirect the user or clear the form here
          showAlert(response);
        })
        .catch((err) => {
          showAlert(err);
          // Here you might want to show an error message to the user
        });
    } catch (error) {
      console.error("Error preparing headers or form submission:", error);
      // Handle errors related to preparing the request, such as generating headers
    }
  };

  return (
    <Box sx={{
      padding: 2,
      paddingTop: 0,
      }}>
      <CustomLoadingModal
        waitToShowModal={saving}
        title="Attribute Group Saved"
        body={`Attribute Group Saved Successfully!`}
        onDismiss={() => navigate(-1)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box maxWidth="md">
          <Box sx={{ mt: 1 }}>
            <Typography variant="h3">
              {attributeId ? "Edit" : "Create"} {attributeScreenConfig?.name}{" "}
              Group
            </Typography>
            <TextField
              required
              fullWidth
              id="name"
              label="Image Class Group"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <AttributeSelectionAndConfiguration
              setSelectedAttributes={setAttributes}
              attributeType={attributeType}
              selectedAttributes={attributes}
            />
          </Box>
          <Button
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {attributeId ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAttributeGroupScreen;
