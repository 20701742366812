import React from "react";
import { Backdrop, CircularProgress, useTheme } from "@mui/material";

const LoadingOverlay = ({ open }) => {
  const theme = useTheme();
  return (
    <Backdrop
      style={{
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
