import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

function ClickableImage({ image, sxImg={} }) {
  const [columns, setColumns] = useState(5);
  const [clickedImage, setClickedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const imageRef = useRef(null);
  const { palette } = useTheme();

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent default context menu
    event.stopPropagation(); // Prevents the event from bubbling up to the Box

    setClickedImage(true);
    setOpenModal(true);
  };

  const handleClose = (event) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the Box
    setClickedImage(false);
    setOpenModal(false);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    imageRef.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        position: "relative",
      }}
      onContextMenu={handleRightClick} // Add this line to ensure right-click is captured
    >
      <Box
        component="img"
        src={image}
        alt={`image`}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          cursor: "context-menu",
          ...sxImg
        }} // Indicate right-click is available
      />

      <Modal open={openModal} onClose={handleClose}>
        <Box
          ref={imageRef}
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundImage: clickedImage !== null ? `url(${image})` : `none`,
            backgroundRepeat: "no-repeat",
            width: "60vw",
            height: "80vh",
            backgroundSize: "200%",
            transition: "background-size 0.3s ease-in-out",
            "&:hover": {
              backgroundSize: "250%",
            },
          }}
          onMouseMove={handleMouseMove}
        />
      </Modal>
    </Box>
  );
}

export default ClickableImage;
