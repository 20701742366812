import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_BAR_HEIGHT } from "../../layout/AppBar/AppBarComponent";
import {
    Grid,
    Box,
    useTheme
  } from "@mui/material";
import ClickableImage from "../../layout/Interactive/ClickableImage";

const StyledGarmentGallery = ({srcs,
    ...props}) => {

    const theme = useTheme();

    return (
            <Grid container sx={{width: "100%", height: "100%", justifyContent: "space-between", flexFlow: "row wrap", marginRight: "auto", marginLeft: "auto", 
                "&::after": {
                    content: "''",
                    flex: "auto",
                }}}>
                {srcs.map((x, index) => {
                    return <Grid item sx={{
                            borderRadius: 2, 
                            border: `1px solid ${theme.palette.primary[300]}`,
                            m: 1
                        }}  xs={3} lg={2} xl={1}>
                        <ClickableImage
                            image={`${x}`}
                            alt={`slide-${index}`}
                            style={{ objectFit: "cover", margin: "auto", borderRadius: 2, width: "100%"}}
                            sxImg={{borderRadius: "2px !important"}}
                        />
                    </Grid>
                })}
            </Grid>
    );
};

export default StyledGarmentGallery; 