import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getHeaders } from "../../../utils/apiUtils";
import debuggerInstance from "../../../components/common/userFeedback/DebugComponent/debugger";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";
import { useDebugger } from "../../../contexts/DebuggerContext";
import StyledCard from "../../../components/ui/cards/StyledCard";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";
import SingleSelectGarmentWindow from "../../../components/layout/Interactive/SingleSelectGarmentWindow";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";

function CatalogingTool(props) {
  const { palette, typography } = useTheme();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [catalogGarments, setCatalogGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState({});
  const [seedGarmentImages, setSeedGarmentImages] = useState([]);
  const [selectedCatalogueItem, setSelectedCatalogueGarment] = useState({});
  const [metaData, setMetaData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [catalogueExhausted, setCatalogueExhausted] = useState(false);
  const [associateGarmentModalOpen, setAssociatedGarmentModalOpen] =
    useState(false);

  const { addLog } = useDebugger();

  // Lifecycle Methods
  useEffect(() => {
    fetchSeedGarment();
  }, []);
  useEffect(() => {
    if (
      selectedCatalogueItem &&
      Object.keys(selectedCatalogueItem).length > 0
    ) {
      setAssociatedGarmentModalOpen(true);
    }
  }, [selectedCatalogueItem]);

  // Axios methods
  const fetchSeedGarment = async () => {
    const headers = await getHeaders();
    setShowLoader(true);
    setPage(1);
    setButtonDisabled(true);
    setPage(1);

    axios
      .get(`${BASE_URL}tools/summon/next-item/basic/cataloging`, headers)
      .then((res) => {
        resetState();

        addLog(res.data.data.groupRepresentative);
        setSeedGarment(res.data.data.groupRepresentative);
        setMetaData(res.data.data);
        let { num_images, uuid_public_id } = res.data.data.groupRepresentative;
        let imageUrls = [];
        for (let i = 1; i <= num_images; i++) {
          let imageUrl = `${BASE_IMAGE_URL}${uuid_public_id}-${i}.jpg`;
          imageUrls.push(imageUrl);
        }
        addLog(res.data.data);
        setSeedGarmentImages(imageUrls);
        queryCatalog({
          vector: res.data.data.groupRepresentative.vector,
          offset: 1,
          page_size: pageSize,
          index: res.data.data.groupRepresentative.index,
        });
        setShowLoader(false);
        setButtonDisabled(false);
      })

      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const queryCatalog = async (params) => {
    const headers = await getHeaders();
    setButtonDisabled(true);
    setShowLoader(true);
    axios
      .post(`${BASE_URL}tools/cataloging/querypinecone`, params, headers)
      .then((res) => {
        setCatalogGarments(res.data.data);
        console.log(res.data.data);
        setShowLoader(false);
        setButtonDisabled(false);
        if (res.data.data.length == pageSize) {
          setCatalogueExhausted(false);
        } else {
          setCatalogueExhausted(true);
        }
      })
      .catch((err) => {
        showAlert(err);
        setShowLoader(false);
        setButtonDisabled(false);
      });
  };

  const createNewCatalogItem = async () => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      vector: seedGarment.vector,
      garment_id: seedGarment.garment_id,
      uuid_public_id: seedGarment.uuid_public_id,
      action: "CATALOGUE",
    };
    setShowLoader(true);
    setButtonDisabled(true);

    axios
      .post(`${BASE_URL}tools/classify/catalogue/new-item`, params, headers)
      .then((res) => {
        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  const asssociateToCatalogItem = async () => {
    const headers = await getHeaders();
    const params = {
      index: seedGarment.index,
      garment_id: seedGarment.garment_id,
      associated_garment: {
        ...selectedCatalogueItem,
        index: selectedCatalogueItem.index + pageSize * (page - 1),
      },
      uuid_public_id: seedGarment.uuid_public_id,
      associated_garment_id: selectedCatalogueItem.garment_id,
      action: "AGGREGATE",
    };
    setShowLoader(true);
    setButtonDisabled(true);
    axios
      .post(`${BASE_URL}tools/classify/catalogue/associate`, params, headers)
      .then((res) => {
        resetState();
        fetchSeedGarment();
        setButtonDisabled(false);
      })
      .catch((err) => {
        showAlert(err);
        setButtonDisabled(false);
      });
  };

  // Functions
  const handleNext = () => {
    queryCatalog({
      vector: seedGarment.vector,
      offset: page + 1,
      page_size: pageSize,
      index: seedGarment.index,
    });
    setPage(page + 1);
  };

  const handleConfirm = () => {
    asssociateToCatalogItem();
    setAssociatedGarmentModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedCatalogueGarment({});
    setAssociatedGarmentModalOpen(false);
  };

  const resetState = () => {
    setSeedGarment({});
    setButtonDisabled(true);
    setSeedGarmentImages([]);
    setMetaData({});
    setCatalogGarments([]);
    setPage(1);
  };
  return (
    <Grid
      container
      spacing={1}
      sx={{
        p: 1,
        pt: 0,
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Cataloguing Tool</Typography>
        </Box>
        <Button
          variant="contained"
          color="success"
          onClick={handleNext}
          disabled={buttonDisabled || catalogueExhausted}
        >
          Next Page
        </Button>
      </Grid>
      <Grid item xs={3}>
        <StyledCard title="Group Representative">
          <StyledImageCarousel srcs={seedGarmentImages} />
          <Typography variant="h4" sx={{ mb: 2 }}>
            {seedGarment.title}
          </Typography>
          <Typography variant="body">
            UUID: {seedGarment.uuid_public_id}
          </Typography>
        </StyledCard>
      </Grid>
      <Grid item xs={9}>
        <SingleSelectGarmentWindow
          selectedGarment={selectedCatalogueItem}
          setSelectedGarment={setSelectedCatalogueGarment}
          garments={catalogGarments}
          additionalComponents={[
            <Grid
              item
              xs={2}
              onClick={(e) => {
                createNewCatalogItem();
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                maxHeight: "200px",
                width: "100%",
                overflow: "hidden",
                cursor: "pointer",
                backgroundColor: palette.primary[200],
                border: `1px solid ${palette.primary[300]}`,
                borderRadius: 2,
                p: 2,
              }}
            >
              <Typography variant="body_sm">
                Create new Catalogue Item
              </Typography>
            </Grid>,
          ]}
        />
      </Grid>
      <CustomModal
        showModal={associateGarmentModalOpen}
        title="Confirm Association"
        body="Are you sure you want to associate the selected garment?"
        onDismiss={handleCancel}
        onConfirm={handleConfirm}
        onDismissTitle="Cancel"
        onConfirmTitle="Confirm"
      />
      <LoadingOverlay open={showLoader} />
    </Grid>
  );
}

export default CatalogingTool;
