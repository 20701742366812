import { Box, Typography } from "@mui/material";
import {
  getHexColorsFromWords,
  colorIsCloserToWhiteThanBlack,
} from "./colorUtils";

export const formatCurrency_GBP = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export const defaultNoImageSrc = `src/assets/images/no-image.svg`;

export const addDefaultImg = (ev) => {
  ev.target.src = defaultNoImageSrc;
};

export const getArrayOfWords = (sentence) => {
  return sentence.split(/\s*,+\s*/);
};

export const capitalizeWords = (sentence, lower = false) => {
  return sentence
    ? (lower ? sentence.toLowerCase() : sentence).replace(
        /(?:^|\s|["'([{])+\S/g,
        (match) => match.toUpperCase()
      )
    : null;
};

export const getProgressColor = (progressString) => {
  switch (progressString.toLowerCase()) {
    //case "complete":
    //  return "purple";
    default:
      return "inherit";
  }
};

export const getGarmentColourComponentsFromSentence = (garmentColors) => {
  const garmentColorArray = getArrayOfWords(garmentColors);
  const hexColorArray = getHexColorsFromWords(garmentColorArray);
  console.log(
    `garmentColors: ${JSON.stringify(garmentColorArray)} ${JSON.stringify(
      hexColorArray
    )}`
  );

  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorArray ? (
        garmentColorArray.map((garmentColor, index) => (
          <Box sx={{ marginRight: 2 }} display="inline-block">
            <Box
              display="inline-block"
              sx={{
                mr: 1,
                mb: "-2px",
                borderRadius: "50%",
                backgroundColor:
                  hexColorArray[index] !== null &&
                  hexColorArray[index] !== undefined
                    ? `${hexColorArray[index]} !important`
                    : "",
                width: "1rem",
                height: "1rem",
              }}
            />
            <Typography
              display="inline"
              sx={{ textTransform: "capitalize !important" }}
            >
              {capitalizeWords(garmentColor.toLowerCase())}
            </Typography>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentColourComponentsFromArrayOfWords = (
  garmentColorArray
) => {
  const hexColorArray = getHexColorsFromWords(garmentColorArray);
  console.log(
    `garmentColors: ${JSON.stringify(garmentColorArray)} ${JSON.stringify(
      hexColorArray
    )}`
  );

  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorArray ? (
        garmentColorArray.map((garmentColor, index) => (
          <Box sx={{ marginRight: 2 }} display="inline-block">
            <Box
              display="inline-block"
              sx={{
                mr: 1,
                mb: "-2px",
                borderRadius: "50%",
                backgroundColor:
                  hexColorArray[index] !== null &&
                  hexColorArray[index] !== undefined
                    ? `${hexColorArray[index]} !important`
                    : "",
                width: "1rem",
                height: "1rem",
              }}
            />
            <Typography
              display="inline"
              sx={{ textTransform: "capitalize !important" }}
            >
              {capitalizeWords(garmentColor.toLowerCase())}
            </Typography>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentColourComponentsFromObject = (garmentColorsObject) => {
  console.log(
    `garmentColorsObjectgarmentColorsObject: ${JSON.stringify(
      garmentColorsObject
    )}`
  );
  //const garmentColorNames = garmentColorsObject.map(x => x.name)
  //const garmentColorRGBs = garmentColorsObject.map(x => x.color)
  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorsObject ? (
        garmentColorsObject.map((garmentColorObject, index) => {
          let innerComponents = <></>;
          if (garmentColorObject) {
            console.log(
              `garmentColorObjectgarmentColorObject: ${JSON.stringify(
                garmentColorObject
              )}`
            );
            const hasName = garmentColorObject.name !== null;
            const hasColor =
              garmentColorObject.red !== null &&
              garmentColorObject.green !== null &&
              garmentColorObject.blue !== null;
            let backgroundColor = hasColor
              ? `rgb(${garmentColorObject.red}, ${garmentColorObject.green}, ${garmentColorObject.blue})`
              : "";
            const hasMulticolorGarmentName =
              hasName &&
              (garmentColorObject.name.toLowerCase() === "multicolour" ||
                garmentColorObject.name.toLowerCase() === "multi colour" ||
                garmentColorObject.name.toLowerCase() === "multi-colour");

            if (hasMulticolorGarmentName) {
              backgroundColor = `linear-gradient(45deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%) !important`;
            }
            innerComponents = hasColor ? (
              <Box sx={{ marginRight: 2 }} display="inline-block">
                <Box
                  display="inline-block"
                  sx={{
                    mr: 1,
                    mb: "-2px",
                    borderRadius: "50%",
                    backgroundColor: !backgroundColor.startsWith(
                      "linear-gradient"
                    )
                      ? backgroundColor
                      : "",
                    background: backgroundColor.startsWith("linear-gradient")
                      ? backgroundColor
                      : "",
                    width: "1rem",
                    height: "1rem",
                    border: colorIsCloserToWhiteThanBlack(garmentColorObject)
                      ? `1px solid black`
                      : "",
                  }}
                />
                {hasName && (
                  <Typography
                    display="inline"
                    sx={{ textTransform: "capitalize !important" }}
                  >
                    {capitalizeWords(garmentColorObject.name.toLowerCase())}
                  </Typography>
                )}
              </Box>
            ) : (
              <></>
            );
          } else {
            innerComponents = <></>;
          }
          console.log(
            `innerComponentsinnerComponents: ${JSON.stringify(innerComponents)}`
          );
          return innerComponents;
        })
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentMaterialComponentsFromObject = (
  garmentMaterialObject
) => {
  let materialString = "";
  if (garmentMaterialObject) {
    const materialsLength = Object.keys(garmentMaterialObject).length;
    garmentMaterialObject.forEach((materialObj, index) => {
      if (materialObj.proportion == null || materialObj.material == null) {
        return;
      }
      const formattedProportion = (materialObj.proportion * 100).toFixed(0);
      materialString =
        materialString +
        `${capitalizeWords(
          materialObj.material.replace("/", " / ")
        )} ${formattedProportion}%${
          materialsLength > 1 && index !== materialsLength - 1 ? `, ` : ""
        }`;
      console.log(
        `materialString: ${JSON.stringify(
          garmentMaterialObject
        )} ${JSON.stringify(materialString)} ${materialsLength}`
      );
    });
  }
  return materialString;
};


export function compareNamesAsc(a,b) {
  return compareStringsAsc(a,b,'name')
}

export function compareStringsAsc(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a['name'].toUpperCase();
  const bandB = b['name'].toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}