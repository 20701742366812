import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// Create a styled Button component that leverages theme and props
export const StyledButton = styled(Button)(({ theme, variant, color }) => ({
  textTransform: "none",
  borderRadius: "5px",
  padding: "10px 20px",
  fontWeight: 500,
  lineHeight: "1.1",
  ...(variant === "text-link" && {
    border: `1px solid ${
      color === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    }`,
    color:
      color === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor:
        color === "primary"
          ? "rgba(25, 118, 210, 0.04)"
          : "rgba(233, 30, 99, 0.04)",
    },
    "&:disabled": {
      borderColor:
        color === "primary"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
      color: theme.palette.action.disabled,
    },
  }),
  ...(variant === "contained" && {
    backgroundColor:
      color === "primary"
        ? theme.palette.primary[700]
        : theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor:
        color === "primary"
          ? theme.palette.primary[600]
          : theme.palette.secondary.dark,
    },
    "&:disabled": {
      backgroundColor:
        color === "primary"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.primary[500]}`,
    },
  }),
  ...(variant === "outlined" && {
    border: `1px solid ${
      color === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    }`,
    color:
      color === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor:
        color === "primary"
          ? "rgba(25, 118, 210, 0.04)"
          : "rgba(233, 30, 99, 0.04)",
    },
    "&:disabled": {
      borderColor:
        color === "primary"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
      color: theme.palette.action.disabled,
    },
  }),
}));

export default StyledButton