import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Chip,
  Paper,
  Tooltip,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
const NUM_COLUMNS = 6;
const TagCarousel = ({ activeTag, setActiveTag, taggedList, images, tags }) => {
  const { palette } = useTheme();

  // Register keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event) => {
      setActiveTag((prevTag) => {
        let activeIndex = tags.findIndex((tag) => tag === prevTag);
        let newIndex = activeIndex;

        switch (event.key) {
          case "ArrowLeft":
            if (activeIndex % NUM_COLUMNS !== 0) {
              // Not at the left edge
              newIndex = activeIndex - 1;
            }
            break;
          case "ArrowRight":
            if (activeIndex % NUM_COLUMNS !== NUM_COLUMNS - 1) {
              // Not at the right edge
              newIndex = activeIndex + 1;
            }
            break;
          case "ArrowUp":
            if (activeIndex >= NUM_COLUMNS) {
              // Not at the top edge
              newIndex = activeIndex - NUM_COLUMNS;
            }
            break;
          case "ArrowDown":
            if (activeIndex < tags.length - NUM_COLUMNS) {
              // Not at the bottom edge
              newIndex = activeIndex + NUM_COLUMNS;
            }
            break;
        }

        if (newIndex >= 0 && newIndex < tags.length) {
          return tags[newIndex];
        } else {
          console.log("HIT");
          return prevTag; // return previous state if no update
        }
      });
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tags, activeTag]);

  const renderTaggedImage = (image, index) => {
    const img_src = images[image];
    return (
      <Box key={index}>
        <img
          src={img_src}
          alt={`Carousel item ${image}`}
          style={{
            width: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
        />
      </Box>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      style={{
        backgroundColor: palette.primary[300],
      }}
    >
      {tags.map((tag, index) => (
        <Grid item xs={12 / NUM_COLUMNS} key={index}>
          <Tooltip title={tag.description}>
            <Box
              style={{
                position: "relative",
              }}
            >
              <Box
                style={{
                  borderRadius: 0,
                  border: "1px solid black",
                  height: 200,
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  padding: 5,
                  display: "flex",

                  backgroundColor:
                    tag === activeTag
                      ? palette.primary[800]
                      : palette.primary[200],
                  width: "100%",
                  color:
                    tag === activeTag
                      ? palette.primary[200]
                      : palette.primary[800],
                }}
              >
                <Typography>{tag.name}</Typography>
              </Box>
              {taggedList[tag.id] && taggedList[tag.id].length > 0 ? (
                <Box
                  style={{
                    position: "absolute",
                    top: 1,
                    left: 1,
                    display: "flex",
                  }}
                >
                  {taggedList[tag.id].map((image, index) => (
                    <Box
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    >
                      {renderTaggedImage(image, index)}
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default TagCarousel;
