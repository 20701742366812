import { Box, Typography, Button, Grid, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import {
  parseISO,
  format,
  startOfQuarter,
  addQuarters,
  isBefore,
} from "date-fns";
import { SplitButton, ShowLegendButton } from "../buttons/Buttons";
import StyledCard from "./StyledCard";
import PriceTrendsLineChart from "../../charts/PriceTrendsLineChart";

const PriceTrendsLineChartCard = ({ data }) => {
  // State for split options
  const [splitByCondition, setSplitByCondition] = useState(false);
  const [splitByVendor, setSplitByVendor] = useState(false);
  const [showLegend, setShowLegend] = useState(true);

  return (
    <StyledCard titleVariant="h2" title={`PRICING TRENDS OVER TIME`} headerChildren={
      <Grid container sx={{justifyContent: "flex-end"}} spacing={1}>
        <Grid item>
          <ShowLegendButton
            action={() => setShowLegend((prev) => !prev)}
            title={showLegend ? "Hide Legend" : "Show Legend"}
            active={showLegend}
          />
        </Grid>

        <Grid item>
          <SplitButton
            action={() => setSplitByCondition((prev) => !prev)}
            title={splitByCondition ? "Combine Conditions" : "Split by Condition"}
            active={splitByCondition}
          />
        </Grid>

        <Grid item>
          <SplitButton
            action={() => setSplitByVendor((prev) => !prev)}
            title={splitByVendor ? "Combine Vendors" : "Split by Vendor"}
            disabled={true}
            active={splitByVendor}
          />
        </Grid>
      </Grid>
    }>

    <PriceTrendsLineChart data={data} splitByCondition={splitByCondition} splitByVendor={splitByVendor} showLegend={showLegend}/>

    </StyledCard>
  );
};

export default PriceTrendsLineChartCard;
