import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useAttributeType } from "../../../contexts/AttributeTypeContext";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import CustomLoadingModal from "../../../components/common/userFeedback/CustomLoadingModal";
import { showAlert, showAlertWithMessage } from "../../../components/common/userFeedback/CustomAlert";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext"

const CreateAttributeScreen = () => {
  const { id } = useParams(); // Retrieve the ID from the URL if present
  const navigate = useNavigate();
  const { attributeType, attributeScreenConfig } = useAttributeType();
  const [saving, setSaving] = useState(false);
  const [showAttributeWarningModal, setShowAttributeWarningModal] = useState(false);

  // Include description in formData state
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const hasDoneOriginalFetchForOriginalFormData = false;
  const [originalFormData, setOriginalFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id && attributeType) {
      fetchAttributeDetails(id);
    }
  }, [id, attributeType]);

  const fetchAttributeDetails = async (id) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${BASE_URL}tools/admin/attribute/${attributeType}/${id}`,
        headers
      );
      const { name, description } = response.data.data;
      console.log("Attribute details:", response.data.data);
      const newFormData = {
        name,
        description,
      }
      setFormData(newFormData);
      
      if (!hasDoneOriginalFetchForOriginalFormData)
      {
        setOriginalFormData(newFormData);
        hasDoneOriginalFetchForOriginalFormData = true;
      }
    } catch (error) {
      showAlert(error);
    }
  };

  const matches = (obj, source) =>
    Object.keys(source).every(
      key => obj.hasOwnProperty(key) && obj[key] === source[key]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    //if name changed, show modal
    //if desc changed, dont show modal but update
    if (!matches(formData, originalFormData))
    {
      if (formData.name !== originalFormData.name)
      {
        setShowAttributeWarningModal(true);
      } else {
        updateAttribute();
      }
    } else {
      showAlertWithMessage(`No change to attribute`);
    }
  };

  const resetAttributeText = async () => {
    setFormData(originalFormData);
  }

  const updateAttribute = async () => {
    setSaving(true);

    const headers = await getHeaders();
    const url = `${BASE_URL}tools/admin/attribute/${attributeType}${
      id ? `/${id}` : ""
    }`;

    axios
      .post(url, formData, headers)
      .then((response) => {
        setSaving(false);
        setOriginalFormData(formData);
        showAlert(response);
      })
      .catch((error) => {
        console.error(
          `Failed to ${id ? "update" : "create"} attribute:`,
          error
        );
        showAlert(
          `Failed to ${id ? "update" : "create"} attribute: ${error.message}`
        );
      });
  }

  return (
    <Box sx={{
      padding: 2,
      paddingTop: 0,}}>
      <CustomLoadingModal
        waitToShowModal={saving}
        title="Attribute Saved"
        body={`Attribute Saved Successfully!`}
        onDismiss={() => navigate(-1)}
      />
      <CustomModal
        showModal={showAttributeWarningModal}
        title="Confirm Attribute Change"
        body={<><Typography>
          Changing this <b>{attributeScreenConfig?.name}</b> Attribute to '<b>{formData.name}</b>' could result in changing 
          <Typography color="red">over 100,000 rows of data in the database.</Typography>
          <br/>
          Are you sure you want to make this change?
        </Typography></>}
        onDismissTitle="Cancel"
        onDismiss={() => {resetAttributeText();setShowAttributeWarningModal(false);}}
        onConfirm={() => {updateAttribute();setShowAttributeWarningModal(false);}}
      />
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Typography variant="h3">
            {id ? "Edit" : "Create"} {attributeScreenConfig?.name} Attribute
          </Typography>
          <TextField
            required
            fullWidth
            id="name"
            label="Attribute Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            {id ? "Update" : "Create"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateAttributeScreen;
