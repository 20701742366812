import { Box, Dialog, Grid, ThemeProvider, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import ImageCarousel from "../../../components/features/ImageAttribute/ImageCarousel";
import TagCarousel from "../../../components/features/ImageAttribute/TagCarousel";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { jobNamesMap } from "../../../config/jobTypes";
// Create a provider for components to consume and subscribe to changes
function ImageAttributeTool() {
  const { inputFilters, jobDetails, jobDataLoaded, toolType, filterHardness } =
    useToolContext();
  const [tagSet, setTagSet] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [images, setImages] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const [taggedList, setTaggedList] = useState({});
  const [garmentDetails, setGarmentDetails] = useState({});
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  useEffect(() => {
    if (jobDataLoaded) {
      fetchNextItem();
      fetchTagSet();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " && activeImage !== null && activeTag !== null) {
        event.preventDefault(); // prevent default tab action

        setTaggedList((prevState) => ({
          ...prevState,
          [activeTag.id]: prevState[activeTag.id]
            ? [...prevState[activeTag.id], activeImage]
            : [activeImage],
        }));
      } else if (event.ctrlKey && !isNaN(event.key)) {
        const indexToRemove = parseInt(event.key) - 1;
        event.preventDefault(); // prevent default tab action
        setTaggedList((prevState) => {
          const newTaggedList = { ...prevState };

          if (
            newTaggedList[activeTag.id] &&
            indexToRemove < newTaggedList[activeTag.id].length
          ) {
            newTaggedList[activeTag.id] = newTaggedList[activeTag.id].filter(
              (_, index) => index !== indexToRemove
            );
          }
          return newTaggedList;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeImage, activeTag, taggedList]); // Add dependencies to useEffect's dependency array

  const fetchNextItem = async () => {
    const headers = await getHeaders();
    const submissionData = {
      filters: inputFilters,
      job_id: jobDetails.job_id,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      filterHardness: filterHardness,
    };
    axios
      .post(`${BASE_URL}tools/jobs/next-item/basic`, submissionData, headers)
      .then((res) => {
        const parseImages = Array.from({
          length: res.data.data.num_images,
        }).map((_, index) => {
          return `${BASE_IMAGE_URL}${res.data.data.uuid_public_id}-${
            index + 1
          }.jpg`;
        });
        setGarmentDetails(res.data.data);
        setImages(parseImages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTagSet = async () => {
    const headers = await getHeaders();
    axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}`,
        headers
      )
      .then((res) => {
        console.log(res.data);
        setTagSet(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetState = () => {
    setActiveImage(null);
    setActiveTag(null);
    setTaggedList({});
    setGarmentDetails({});
    setImages([]);
  };

  async function saveData() {
    // Your save data logic goes here
    if (Object.keys(taggedList).length === 0) {
      return;
    }
    const images = [];
    Object.keys(taggedList).map((key) => {
      taggedList[key].map((image) => {
        images.push({ image_num: image + 1, image_class: key });
      });
    });

    const params = {
      garmentId: garmentDetails.garment_id,
      jobId: jobDetails.job_id,
      images: images,
      confidence: 0.4,
      pipelineJobId: jobDetails.pipeline_job_link_id,
    };
    let headers = await getHeaders();
    await axios
      .post(`${BASE_URL}tools/jobs/classify-images`, params, headers)
      .then((response) => {
        if (!response.data) {
          throw new Error("Tag Data not found");
        }
        trackEvent("Proposal", {
          garmentId: garmentDetails.garment_id,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job, 
          toolType: jobNamesMap[jobDetails.job],
          toolName: "ImageAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: false,
          qa: false
        });
        resetState();
        fetchNextItem();
      })
      .catch((error) => {
        //Handle All Errors Here
        console.log(error);
      });
  }

  const skipItem = async () => {
    console.log("Skipping");
    trackEvent("Proposal", {
      garmentId: garmentDetails.garment_id,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      pipelineName: jobDetails.pipeline_name,
      jobId: jobDetails.job_id,
      jobName: jobDetails.job, 
      toolType: jobNamesMap[jobDetails.job],
      toolName: "ImageAttributeTool",
      confidence: jobDetails.qa_confidence,
      skipped: true,
      qa: false
    });
  };

  return (
    <KeyEventHandler onEnter={saveData} onTab={skipItem}>
      <Grid
        container
        spacing={1}
        sx={{
          p: 1,
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography
            variant="h4"
            color={"primary.800"}
            sx={{
              flex: 1,
            }}
          >
            Image Tagging Tool
          </Typography>
          <Typography variant="body1">
            Garment ID: {garmentDetails.garment_id}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <ImageCarousel
            images={images}
            setActiveImage={setActiveImage}
            activeImage={activeImage}
          />
        </Grid>
        <Grid item xs={8}>
          <TagCarousel
            tags={tagSet}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            images={images}
            taggedList={taggedList}
          />
        </Grid>
      </Grid>
    </KeyEventHandler>
  );
}
function WrappedImageAttributeTool() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <ImageAttributeTool />
    </ToolProvider>
  );
}

export default WrappedImageAttributeTool;
