// SearchableMultiSelect.js
import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";

const SearchableMultiSelect = ({
  typeMapping,
  selected,
  onSelectedChange,
  label,
  hasAny = false,
  renderComponent = null,
}) => {
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const componentRef = useRef(); // Create a ref for the component

  // Function to call on outside click
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsFocused(false); // If the click is outside, lose focus
    }
  };

  useEffect(() => {
    // Add when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener on cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  // Function to fetch filtered options from the database
  const fetchOptions = async (searchTerm) => {
    try {
      let headers = await getHeaders();

      // Construct the query parameters
      const response = await axios.get(
        `${BASE_URL}tools/general/filteroptions/listall/${typeMapping}?search=${searchTerm}`,
        headers
      );
      if (hasAny) {
        setOptions([{ id: "ANY", name: "ANY" }, ...response.data.data]);
      } else {
        setOptions(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch options", error);
    }
  };

  // Debounced version of the fetchOptions function
  const debouncedFetchOptions = debounce(fetchOptions, 300);

  useEffect(() => {
    // Call the debounced fetch function whenever the filter changes
    if (filter || isFocused) {
      debouncedFetchOptions(filter);
    } else {
      setOptions([]); // Reset options if filter is cleared
    }

    // Cleanup the debounced function on unmount
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [filter, typeMapping, isFocused]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSelectionChange = (event, option) => {
    if (event.target.checked) {
      onSelectedChange([...selected, option]); // Add the whole option object
    } else {
      onSelectedChange(selected.filter((item) => item.id !== option.id)); // Remove by id
    }
  };

  return (
    <Box ref={componentRef}>
      {/* Attach the ref to the top-level Box */}
      <FormControl component="fieldset" variant="standard">
        <TextField
          variant="outlined"
          label={label}
          value={filter}
          onChange={handleFilterChange}
          margin="normal"
          onFocus={() => setIsFocused(true)}
          // onBlur={() => setIsFocused(false)} // Optional: In case you want to unset focus when user tabs out of the input
        />
        <FormGroup>
          {isFocused &&
            options.map((option) => {
              if (renderComponent) return renderComponent(option);
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected.some(
                        (selectedOption) => selectedOption.id === option.id
                      )}
                      onChange={(e) => handleSelectionChange(e, option)}
                      name={option.name}
                    />
                  }
                  label={option.name}
                  key={option.id}
                />
              );
            })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SearchableMultiSelect;
