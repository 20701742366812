import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getCurrentTimestamp } from "../../../utils/dateUtils";

function GarmentAttributeQaTool() {
  const {
    jobDetails,
    jobDataLoaded,
    toolType,
    allPipelineJobs,
    shownAttributes,
  } = useToolContext();
  const { palette } = useTheme();
  const [numUsers, setNumUsers] = useState(4);
  const [tagSet, setTagSet] = useState([]);
  const [garments, setGarments] = useState([]);
  const [rejectedTags, setRejectedTags] = useState([]);
  const [selectAllGarments, setSelectAllGarments] = useState(false);

  const [selectedTag, setSelectedTag] = useState(null);
  const [loading, setLoading] = useState(true);
  const [additionalPossiblePipelineJobs, setAdditionalPossiblePipelineJobs] =
    useState([]);
  const [additionalPipelineJobs, setAdditionalPipelineJobs] = useState([]);
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  //Axios Calls
  const fetchTagSet = async () => {
    const headers = await getHeaders();
    axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}`,
        headers
      )
      .then((res) => {
        setTagSet(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPipelineJobIds = async () => {
    const headers = await getHeaders();
    const jobsToFetch = jobQaMap[toolType];
    axios
      .get(
        `${BASE_URL}tools/pipelines/list-pipeline-jobs/${jobsToFetch}`,
        headers
      )
      .then((res) => {
        setAdditionalPossiblePipelineJobs(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  const fetchNextItemBatch = async () => {
    const headers = await getHeaders();
    const jobsToQa = jobQaMap[toolType];
    const jobIdsToQa = allPipelineJobs
      .filter((job) => jobsToQa.includes(job.job))
      .map((job) => job.pipeline_job_link_id);
    const fullListofIdsToQA = [...additionalPipelineJobs, ...jobIdsToQa];
    const submissionData = {
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      pipelineJobIdsToQa: fullListofIdsToQA,
      selectedTag: selectedTag,
      userCount: numUsers,
      jobID: jobDetails.job_id,
      shownAttributes: shownAttributes,
    };
    setLoading(true);
    axios
      .post(
        `${BASE_URL}tools/summon/next-item/qa/multi-and`,
        submissionData,
        headers
      )
      .then((res) => {
        setGarments(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setGarments([]);
        setLoading(false);
        showAlert(err);
      });
  };

  const submitItems = async () => {
    const headers = await getHeaders();
    setLoading(true);
    const rejectedIds = rejectedTags.map((t) => t.id);
    let approved = garments
      .filter((image) => !rejectedIds.includes(image.garment_id))
      .map((image) => image.garment_id);
    let rejected = garments
      .filter((image) => rejectedIds.includes(image.garment_id))
      .map((image) => image.garment_id);
    const data = {
      approved: approved,
      rejected: rejected,
      proposal_value_id: selectedTag,
      jobId: jobDetails.job_id,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      toolType: toolType,
    };
    await axios
      .post(`${BASE_URL}tools/classify/qa/multi-and`, data, headers)
      .then((res) => {
        fetchNextItemBatch();
        setRejectedTags([]);

        trackEvent("ProposalQA", {
          approvedGarmentIds: approved,
          rejectedGarmentIds: rejected,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: toolType,
          toolName: "GarmentAttributeQATool",
          confidence: jobDetails.qa_confidence,
          skipped: approved.length === 0,
          qa: true,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  };

  //Use Effects
  useEffect(() => {
    if (jobDataLoaded) {
      fetchTagSet();
      fetchPipelineJobIds();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    if (selectedTag) {
      fetchNextItemBatch();
    }
  }, [selectedTag, numUsers, additionalPipelineJobs]);

  //Handlers
  const handleSliderChange = (event, newValue) => {
    setNumUsers(newValue);
  };

  const handleSelectAllGarments = (selectOrDeselectAll) => {
    const localRejectedTags = garments.map(garm => {return {
      id: garm.garment_id,
      proposed_timestamp: getCurrentTimestamp(),
    }});
    
    setRejectedTags(selectOrDeselectAll ? localRejectedTags : [])
    setSelectAllGarments(!selectAllGarments)
  }

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <LoadingOverlay open={loading} />
      <Grid container
        style={{
          display: "flex",
          justifyContent: "space-between !important",
          alignItems: "center",
        }} spacing={2}
      >
        <Grid item xs={12} md={6} lg={2} sx={{
          justifyContent: "center",
          alignItems: "center",
          }}>
          <Typography id="tag-selector" variant="h8" gutterBottom>
            Selected Tag:
          </Typography>
          <Select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            sx={{
              width: "100%",
              mt: 1,
            }}
          >
            <MenuItem key={null} value={null}>
              Select Tag
            </MenuItem>
            {tagSet.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={2} sx={{
          justifyContent: "center",
          alignItems: "center",
          }}>
          <Typography id="pipeline-job-selector" variant="h8" gutterBottom>
            Additional Pipelines:
          </Typography>
          <Autocomplete
            multiple
            options={additionalPossiblePipelineJobs}
            disableCloseOnSelect
            getOptionLabel={(option) => option.pipeline}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.pipeline}
              </li>
            )}
            value={additionalPipelineJobs.map((jobId) =>
              additionalPossiblePipelineJobs.find((job) => job.id === jobId)
            )}
            onChange={(event, newValue) => {
              setAdditionalPipelineJobs(newValue.map((item) => item.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Pipelines"
                margin="normal"
              />
            )}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4} sx={{
          justifyContent: "center",
          alignItems: "center",
          }}>
          <Typography id="input-slider" variant="body" gutterBottom>
            Select a number of users:
          </Typography>
          <Slider
            value={numUsers}
            min={1}
            max={10}
            marks
            step={1}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="input-slider"
          />
          <Typography variant="body">Selected Number: {numUsers}</Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={2} sx={{
          justifyContent: "center",
          alignItems: "center",
          }}>
          <Button
            variant="contained-white"
            color="primary"
            sx={{
              //overflow: "hidden",
              //whiteSpace: "nowrap"
              width: "100%"
            }}
            onClick={() => submitItems()}
          >
            SUBMIT
          </Button>
        </Grid>
        
        <Grid item xs={6} md={6} lg={2}>
          <Button
            variant="contained-white"
            color="primary"
            sx={[{
              //overflow: "hidden",
              //whiteSpace: "nowrap"
              width: "100%",
              },
              selectAllGarments && {
                  backgroundColor: `${palette.primary[300]} !important`,
                  color: `${palette.primary.main} !important`
              }]}
            onClick={() => handleSelectAllGarments(!selectAllGarments)}
          >
            {selectAllGarments ? `DELECT` : `SELECT`} ALL
          </Button>
        </Grid>
      </Grid>
      {selectedTag ? (
        (garments.length === 0) & !loading ? (
          <NoDataScreenCover showBackButton={false} />
        ) : (
          <MassImageApprovalsFrame
            tags={tagSet}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            preselectAll={selectAllGarments}
            preDeselectAll={!selectAllGarments}
            garments={garments}
            rejectedTags={rejectedTags}
            setRejectedTags={setRejectedTags}
            shownAttributes={shownAttributes}
          />
        )
      ) : (
        <TakeActionScreenCover />
      )}
    </Box>
  );
}

function WrappedGarmentQaTool() {
  //Job Pipeline ID
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <GarmentAttributeQaTool />
    </ToolProvider>
  );
}

export default WrappedGarmentQaTool;
