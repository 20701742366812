import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { Dialog, CircularProgress } from "@mui/material";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { useParams } from "react-router-dom";
import { ToolProvider, useToolContext } from "../../../../contexts/ToolContext";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import { PaginationButtonSet } from "../../../../components/ui/buttons/TableButtons";
import GarmentCard from "../../../../components/ui/cards/GarmentCard";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import SearchableMultiSelect from "../../../../components/forms/Dropdowns/SearchableMultiSelectV2";
import StatusMultiSelect from "../../../../components/forms/Dropdowns/StatusMultiSelect";
import OrderByDropdown from "../../../../components/forms/Dropdowns/OrderBy";
import LoadingOverlay from "../../../../components/common/userFeedback/LoadingOverlay";

function PipelineJobsDataview() {
  const { jobDetails, pipelineJobId, jobDataLoaded, toolType } =
    useToolContext();
  const [rawProposals, setRawProposals] = useState([]); // Raw data from API
  const [page, setPage] = useState(1);
  const { palette, typography } = useTheme();
  const itemsPerPage = 12; // You can adjust the items per page as needed
  const [dataLoading, setDataLoading] = useState(true);
  const [dataExhausted, setDataExhausted] = useState(false);
  const [fixProposals, setFixProposals] = useState(false);
  const [orderBy, setOrderBy] = useState("DEFAULT");

  //Filters
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [proposalStatuses, setProposalStatuses] = useState([]);
  const resetData = () => {
    console.log();
  };

  const fetchData = async () => {
    setDataLoading(true);
    setRawProposals([]);

    const params = {
      page: page,
      limit: itemsPerPage,
      pipelineJobId: pipelineJobId,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      filters: {
        USERS: users.map((u) => u.id),
        LABELS: labels.map((l) => l.id),
        PROPOSAL_STATUSES: proposalStatuses.map((ps) => ps.name),
      },
      orderBy: orderBy,
    };
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/pipeline-job-dataview`,
        params,
        headers
      );
      setDataLoading(false);
      setRawProposals(response.data.data);

      if (response.data.data.length < itemsPerPage) {
        setDataExhausted(true);
      } else {
        setDataExhausted(false);
      }
      console.log(`SUCCESS: ${JSON.stringify(response.data.data)}`)
    } catch (error) {
      console.log(`ERROR: ${error}`)
      showAlert(error); // Automatically extracts the message and shows the alert
    }
  };
  useEffect(() => {
    setPage(1);
  }, [users, labels, proposalStatuses, orderBy]);
  useEffect(() => {
    resetData();
    if (jobDataLoaded) {
      fetchData();
    }
  }, [
    jobDataLoaded,
    pipelineJobId,
    page,
    users,
    labels,
    proposalStatuses,
    orderBy,
  ]);

  const handleFixProposalsChange = (event) => {
    setFixProposals(event.target.checked);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoadingOverlay open={dataLoading} />

      <Box
        sx={{
          p: 1,
        }}
      >
        <Typography variant="h2">Pipeline Job Data View</Typography>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SearchableMultiSelect
              typeMapping={"USER"}
              selected={users}
              onSelectedChange={setUsers}
              label={"Select Users"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SearchableMultiSelect
              typeMapping={"STYLE"}
              selected={labels}
              onSelectedChange={setLabels}
              label={"Select Style"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <StatusMultiSelect
              selected={proposalStatuses}
              onSelectedChange={setProposalStatuses}
              label={"Select Proposal Status"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <OrderByDropdown setOrderBy={setOrderBy} orderBy={orderBy} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fixProposals}
                  onChange={handleFixProposalsChange}
                  color="primary" // This is the color of the checkbox
                />
              }
              label={<Typography variant="h8">Fix Proposals</Typography>}
              sx={{
                "& .MuiTypography-root": {
                  // Style the label
                  fontSize: "1rem", // Adjust font size as needed
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flex: 1, // Take up remaining space
          overflowY: "auto", // Make the container scrollable
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: palette.primary[300],
            width: "100%",
            p: 1,
            height: "100%",
          }}
        >
          {rawProposals.map((garmentData) => (
            <Grid item xs={3} key={garmentData.id}>
              {/* Ensure each item has a unique key */}
              <GarmentCard
                garmentData={garmentData}
                fixProposals={fixProposals}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          height: 100,
        }}
      >
        <PaginationButtonSet
          setPage={setPage}
          page={page}
          disabled={dataLoading}
          nextPageDisabled={dataExhausted}
          previousPageDisabled={page == 1}
        />
      </Box>
    </Box>
  );
}
function WrappedPipelineJobsDataview() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <PipelineJobsDataview />
    </ToolProvider>
  );
}

export default WrappedPipelineJobsDataview;
