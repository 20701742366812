import {
  Avatar,
  Box,
  Divider,
  Typography,
  IconButton,
  useTheme,
  createTheme,
  Grid,
  Button,
} from "@mui/material";
import StyledButton from "./buttons/StyledButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StyledCard from "./cards/StyledCard";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL } from "../../config/apiConfig";
import ClickableImage from "../layout/Interactive/ClickableImage";

const styles = {
  "& .alice-carousel": {},
};

const StyledImageCarousel = ({
  title,
  titleVariant = "h4",
  headerChildren,
  srcs = null,
  sx = {},
}) => {
  const theme = useTheme();

  //const responsive = {
  //    0: { items: 1 },
  //    568: { items: 2 },
  //    1024: { items: 3 },
  //};
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? srcs.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === srcs.length - 1 ? 0 : prevIndex + 1
    );
  };

  const defaultHeight = "500px";

  return (
    <Box sx={{ 
        borderRadius: 2, 
        border: `1px solid ${theme.palette.primary[300]}`, 
        padding: 2,
        maxHeight: "100%",
        maxWidth: "100%",
        height: "100%",
        width: "100%",
        minHeight: "100%",
        minWidth: "100%",
        position: "relative", paddingBottom: "2rem"
        }}>
      {/* Image Display */}
      <Box
        sx={{
            position: "relative",
            marginBottom: 4,
            overflow: "hidden",
            paddingLeft: "2rem", paddingRight: "2rem", borderRadius: 2,
            maxHeight: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            minWidth: "100%",
            height: defaultHeight,
            width: "100%",
            paddingBottom: 1
        }}
      >
        <ClickableImage
          image={`${srcs[currentIndex]}`}
          alt={`slide-${currentIndex}`}
          style={{ objectFit: "cover", margin: "auto", borderRadius: 2, }}
          sxImg={{borderRadius: "2px !important"}}
        />
      </Box>

      {/* Navigation Arrows */}
      <Grid container sx={{ justifyContent: "space-between", zIndex: 10, width: "auto",
        right: "1rem", left: "1rem",
        position: "absolute",
        top: "60%",
        transform: "translateY(-60%)" }}>
        <Grid item>
            <Button variant="longblock" onClick={handlePrevClick} disabled={srcs.length < 2}>
                <KeyboardArrowLeftIcon />
            </Button>
        </Grid>

        <Grid item>
            <Button variant="longblock" onClick={handleNextClick} disabled={srcs.length < 2}>
                <KeyboardArrowRightIcon />
            </Button>
        </Grid>
      </Grid>

      {/* Dots Indicator */}
      <Box sx={{ display: "flex", justifyContent: "center", position: "absolute", bottom: "1rem", left: 0, right: 0 }}>
        {srcs.map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            sx={{
              width: 8,
              height: 8,
              backgroundColor:
                index === currentIndex ? theme.palette.primary[800] : theme.palette.primary[100],
              marginX: 1,
              border: `2px solid ${theme.palette.primary[800]}`,
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StyledImageCarousel;
