import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RecyclingIcon from "@mui/icons-material/Recycling";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DangerousIcon from "@mui/icons-material/Dangerous";
import InsightsIcon from "@mui/icons-material/Insights";
import PageviewIcon from "@mui/icons-material/Pageview";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export const EditButton = ({ onEdit }) => {
  const { palette } = useTheme();
  return (
    <Box
      onClick={onEdit}
      sx={{
        cursor: "pointer",
        border: `1px solid ${palette.select[400]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        '&:hover': {
          backgroundColor: palette.select[400],
          transition: "linear 0.1s"
        },
        '&:hover .edit-button-icon': {
          color: `${palette.primary[100]} !important`,
          transition: "linear 0.1s"
        },
        '&:hover .edit-button-text': {
          color: `${palette.primary[100]} !important`,
          transition: "linear 0.1s"
        }
      }}
    >
      <EditIcon sx={{ color: palette.select[400], mr: 0.5, fontSize: 16 }} className="edit-button-icon"/>
      <Typography sx={{ color: `${palette.select[400]} !important` }} className="edit-button-text" variant="btn_txt_sm">
        Edit
      </Typography>
    </Box>
  );
};

export const DeleteButton = ({ onDelete, disabled = false }) => {
  const { palette } = useTheme();
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    onDelete();
  };
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? "none" : "pointer",
        border: disabled
          ? `1px solid ${palette.primary[400]}`
          : `1px solid ${palette.error.main}`,
        borderRadius: 1,
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <DeleteIcon
        fontSize="small"
        sx={{
          color: disabled ? palette.primary[400] : palette.error.main,
          mr: 0.5,
          fontSize: 16,
        }}
      />
      <Typography
        sx={{ color: disabled ? palette.primary[400] : palette.error.main }}
        variant="btn_txt_sm"
      >
        Delete
      </Typography>
    </Box>
  );
};

export const ViewButton = ({ action, isBlack=false}) => {
  const theme = useTheme();
  return (
    <Box
      onClick={action}
      sx={{
        cursor: "pointer",
        border: `1px solid ${isBlack ? theme.palette.primary[600] : theme.palette.tertiary[400]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        transition: "linear 0.1s",
        '&:hover .view-button-icon': {
          color: theme.palette.primary[100],
          transition: "linear 0.1s"
        },
        '&:hover .view-button-text': {
          color: `${theme.palette.primary[100]} !important`,
          transition: "linear 0.1s"
        },
        '&:hover': {
          backgroundColor: isBlack ? theme.palette.primary[600] : theme.palette.tertiary[400],
          transition: "linear 0.1s"
        }
      }}
    >
      <VisibilityIcon sx={{ color: isBlack ? theme.palette.primary[600] : theme.palette.tertiary[400], mr: 0.5, fontSize: 16 }} className="view-button-icon" />
      <Typography sx={{ color: isBlack ? `${theme.palette.primary[600]} !important` : `${theme.palette.tertiary[400]} !important` }} className="view-button-text" variant="btn_txt_sm">
        View
      </Typography>
    </Box>
  );
};

export const EnableButton = ({ action }) => {
  return (
    <Box
      onClick={action}
      sx={{
        cursor: "pointer",
        border: `1px solid #1e8bc9`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <RecyclingIcon sx={{ color: "#1e8bc9", mr: 0.5, fontSize: 16 }} />
      <Typography sx={{ color: "#1e8bc9" }} variant="btn_txt_sm">
        Enable
      </Typography>
    </Box>
  );
};
export const DisableButton = ({ action, disabled = false }) => {
  const { palette } = useTheme();
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? "none" : "pointer",
        border: disabled
          ? `1px solid ${palette.primary[400]}`
          : `1px solid ${palette.error.main}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        '&:hover .disable-button-icon': {
          color: palette.primary[100],
        },
        '&:hover .disable-button-text': {
          color: `${palette.primary[100]} !important`,
        },
        '&:hover': {
          backgroundColor: palette.error.main,
          transition: "linear 0.1s"
        }
      }}
    >
      <DangerousIcon
        sx={{
          color: disabled ? palette.primary[400] : palette.error.main,
          mr: 0.5,
          fontSize: 16,
        }}
        className="disable-button-icon"
      />
      <Typography
        sx={{ color: disabled ? `${palette.primary[400]} !important` : `${palette.error.main} !important` }}
        variant="btn_txt_sm"
        className="disable-button-text"
      >
        Disable
      </Typography>
    </Box>
  );
};
export const StartButton = ({ action, disabled = false }) => {
  const { palette } = useTheme();
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: "pointer",
        border: `1px solid ${palette.success.main}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <PlayCircleOutlineIcon
        sx={{ color: palette.success.main, mr: 0.5, fontSize: 16 }}
      />
      <Typography sx={{ color: palette.success.main }} variant="btn_txt_sm">
        Start
      </Typography>
    </Box>
  );
};

export const AnalyticsButton = ({ action, disabled = false }) => {
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  const { palette } = useTheme();
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? null : "pointer",
        border: `1px solid ${disabled ? palette.analytics[800] : palette.analytics[400]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: disabled ? palette.primary[400] : palette.primary[100],
        '&:hover .analytics-button-icon': {
          color: !disabled && palette.primary[100],
        },
        '&:hover .analytics-button-text': {
          color: !disabled && `${palette.primary[100]} !important`,
        },
        '&:hover': {
          backgroundColor: !disabled && palette.analytics[400],
          transition: "linear 0.1s"
        }
      }}
    >
      <InsightsIcon
        sx={{ color: disabled ? palette.analytics[800] : palette.analytics[400], mr: 0.5, fontSize: 16 }} className="analytics-button-icon"
      />

      <Typography
        sx={{ color: disabled ? `${palette.analytics[800]} !important` : `${palette.analytics[400]} !important` }}
        variant="btn_txt_sm"
        className="analytics-button-text"
      >
        Analytics
      </Typography>
    </Box>
  );
};

export const DataViewButton = ({ action, disabled = false }) => {
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    action();
  };
  const { palette } = useTheme();
  return (
    <Box
      onClick={handleClicked}
      sx={{
        cursor: disabled ? null : "pointer",
        border: `1px solid ${disabled ? palette.tertiary[800] : palette.tertiary[400]}`,
        borderRadius: 1,
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: disabled ? palette.primary[400] : palette.primary[100],
        '&:hover .dataview-button-icon': {
          color: !disabled && palette.primary[100],
        },
        '&:hover .dataview-button-text': {
          color: !disabled && `${palette.primary[100]} !important`,
        },
        '&:hover': {
          backgroundColor: !disabled && palette.tertiary[400],
          transition: "linear 0.1s"
        }
      }}
    >
      <InsightsIcon
        sx={{ color: disabled ? palette.tertiary[800] : palette.tertiary[400], mr: 0.5, fontSize: 16 }} className="dataview-button-icon"
      />

      <Typography
        sx={{ color: disabled ? `${palette.tertiary[800]} !important` : `${palette.tertiary[400]} !important` }}
        variant="btn_txt_sm"
        className="dataview-button-text"
      >
        Data View
      </Typography>
    </Box>
  );
};

export const PaginationButtonSet = ({
  setPage,
  page,
  disabled = false,
  nextPageDisabled,
  previousPageDisabled,
}) => {
  const handleClicked = () => {
    if (disabled) {
      return;
    }
    setPage((prev) => prev + 1);
  };

  const { palette } = useTheme();
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        p: 2,
      }}
    >
      <Box
        onClick={() => {
          if (disabled || previousPageDisabled) return;
          setPage((prev) => prev - 1);
        }}
        sx={{
          cursor: disabled || previousPageDisabled ? null : "pointer",
          border: `1px solid ${
            disabled || previousPageDisabled
              ? palette.primary[800]
              : palette.primary[600]
          }`,
          borderRadius: 50,
          p: 0.5,
          width: 50,
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor:
            disabled || previousPageDisabled
              ? palette.primary[400]
              : palette.primary[100],
        }}
      >
        <ArrowBackIosIcon
          sx={{
            color:
              disabled || previousPageDisabled
                ? palette.primary[800]
                : palette.primary[600],
            fontSize: 20,
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          px: 2,
        }}
      >
        {page}
      </Typography>
      <Box
        onClick={() => {
          if (disabled || nextPageDisabled) return;

          setPage((prev) => prev + 1);
        }}
        sx={{
          cursor: disabled || nextPageDisabled ? null : "pointer",
          border: `1px solid ${
            disabled || nextPageDisabled
              ? palette.primary[800]
              : palette.primary[600]
          }`,
          borderRadius: 50,
          p: 0.5,
          width: 50,
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor:
            disabled || nextPageDisabled
              ? palette.primary[400]
              : palette.primary[100],
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            color:
              disabled || nextPageDisabled
                ? palette.primary[800]
                : palette.primary[600],
            fontSize: 20,
          }}
        />
      </Box>
    </Box>
  );
};
