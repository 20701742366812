import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import ClickableImage from "../../../components/layout/Interactive/ClickableImage";
import { getHeaders } from "../../../utils/apiUtils";
import GarmentPanel from "./GarmentPanel";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";

function AggregationTool(props) {
  const theme = useTheme();
  const [garments, setGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState(null);
  const [garmentID, setGarmentID] = useState(null);
  const [publicGarmentId, setPublicGarmentId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);

  const [selectedGarments, setSelectedGarments] = useState({});
  const [ignoredGarments, setIgnoredGarments] = useState({});
  const [totalAggregations, setTotalAggregations] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  const [trainingMode, setTrainingMode] = useState(false);
  const [openSearchIndex, setOpenSearchIndex] = useState("vetements");
  const [pagesWithoutTag, setPagesWithoutTag] = useState(0);
  const [tagMadeOnPage, setTagMadeOnPage] = useState(true);
  const [numRecords, setNumRecords] = useState(0);

  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  //Lifecycle Methods
  useEffect(() => {
    fetchData();
    return () => {
      setTotalAggregations(0);
      setSeedGarment(null);
      setGarmentID(null);
      setPage(1);
      setGarments([]);
      setSelectedGarments([]);
      setDataLoading(false);
    };
  }, [trainingMode, openSearchIndex, selectedDataset]);

  useEffect(() => {
    if (garmentID) {
      getSimiliarItems();
    }
  }, [garmentID, page]);

  useEffect(() => {
    getIndexes();
    getDatasets();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedGarments).length > 0) {
      setTagMadeOnPage(true);
      setPagesWithoutTag(1);
    }
  }, [selectedGarments]);

  //API Calls
  async function getSimiliarItems() {
    setDataLoading(true);
    const headers = await getHeaders();
    let data = {
      garment_id: publicGarmentId,
      offset: (page - 1) * pageSize,
      page_size: pageSize,
      index: openSearchIndex,
    };
    setGarments([]);

    await axios
      .post(`${BASE_URL}tools/aggregation/querypinecone`, data, headers)
      .then((res) => {
        setGarments(res.data.data);
        //set ignored garments to all returned garments
        let newIgnoredGarments = Object.assign({}, ignoredGarments);
        res.data.data.forEach((garment, index) => {
          newIgnoredGarments[garment.garment_id] = {
            score: garment.score,
            rank: index + 1 + (page - 1) * pageSize,
            timestamp: new Date().toISOString(),
          };
        });
        setIgnoredGarments(newIgnoredGarments);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setPagesWithoutTag(3);
      });
    setDataLoading(false);
  }
  async function getIndexes() {
    setDataLoading(true);
    const headers = await getHeaders();

    await axios
      .get(`${BASE_URL}tools/aggregation/list-indexes`, headers)
      .then((res) => {
        console.log(res.data.data);
        setOpenSearchIndexes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
        showAlert(err);
      });
  }
  async function getDatasets() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );
      setDatasets(res.data.data);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }

  async function fetchData() {
    setDataLoading(true);
    const headers = await getHeaders();
    setSeedGarment(null);
    setGarmentID(null);
    setGarments([]);
    setPage(1);
    setSelectedGarments([]);
    setTagMadeOnPage(false);
    setPagesWithoutTag(0);
    let url = `${BASE_URL}tools/aggregation/nextitem/training`;
    if (!trainingMode) {
      url = `${BASE_URL}tools/aggregation/nextitem/basic/${openSearchIndex}?garment_count=${numRecords}`;
      if (selectedDataset?.id) {
        url += `&dataset=${selectedDataset.id}`;
      }
    }
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 204) {
          console.log("No more data");
          setTrainingMode(false);
          return;
        }
        const { num_images, uuid_public_id } = res.data.data;
        let newImages = [];
        for (let i = 0; i < num_images; i++) {
          const prefix =
            "https://scraped-garment-images.s3.eu-west-2.amazonaws.com/";
          const suffix = `${uuid_public_id}-${i + 1}.jpg`;
          newImages.push(prefix + suffix);
        }

        setSeedGarment({ ...res.data.data, images: newImages });
        setPublicGarmentId(res.data.data.uuid_public_id);
        setGarmentID(res.data.data.garment_id);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  }

  //Handlers
  const handleNext = () => {
    if (!tagMadeOnPage) {
      setPagesWithoutTag(pagesWithoutTag + 1);
    }
    setTagMadeOnPage(false);
    setPage(page + 1);

    trackEvent("AggregationNextPage", {
      index: openSearchIndex,
      datasetId: selectedDataset?.id,
      garmentId: garmentID,
      aggregations: Object.keys(selectedGarments).length,
      totalAggregations: totalAggregations,
      selectedGarmentIds: Object.keys(selectedGarments),
      ignoredGarmentIds: Object.keys(ignoredGarments),
      jobId: 17,
      jobName: "aggregation",
      toolType: "AGGREGATION",
      toolName: "AggregationTool",
      qa: false,
      pages: page,
    });
  };

  const handleSave = async () => {
    setDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: 17,
      selected_garments: selectedGarments,
      ignored_garments: ignoredGarments,
    };
    const headers = await getHeaders();

    if (selectedGarments.length === 0) {
      await fetchData();
      setDataLoading(false);
      return;
    }
    await axios
      .post(`${BASE_URL}tools/aggregation/classify`, data, headers)
      .then((res) => {
        setSelectedGarments({});
        setIgnoredGarments({});
        fetchData();
        setTotalAggregations(
          totalAggregations + Object.keys(selectedGarments).length
        );

        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations: totalAggregations,
          selectedGarmentIds: Object.keys(selectedGarments),
          ignoredGarmentIds: Object.keys(ignoredGarments),
          jobId: 17,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: false,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
    setDataLoading(false);
  };
  const handleSkip = async () => {
    setDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: 17,
    };
    const headers = await getHeaders();

    await axios
      .post(`${BASE_URL}tools/aggregation/skip`, data, headers)
      .then((res) => {
        setSelectedGarments({});
        setIgnoredGarments({});
        fetchData();
        setTotalAggregations(totalAggregations + selectedGarments.length);

        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations: totalAggregations,
          selectedGarmentIds: Object.keys(selectedGarments),
          ignoredGarmentIds: Object.keys(ignoredGarments),
          jobId: 17,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: true,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
    setDataLoading(false);
  };

  //Renderers
  const renderGarment = (garment, index) => {
    return (
      <GarmentPanel
        garment={garment}
        setSelectedGarments={setSelectedGarments}
        setIgnoredGarments={setIgnoredGarments}
        ignoredGarments={ignoredGarments}
        selectedGarments={selectedGarments}
        index={index + 1 + (page - 1) * pageSize} // Calculate index based on page and page size
      />
    );
  };

  return (
    <>
      <LoadingOverlay open={dataLoading} />
      <Grid
        container
        sx={{
          p: 1,
          pt: 0,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="h2">Aggregation Tool</Typography>
            <Typography
              variant="h3"
              sx={{
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              {trainingMode ? "Training Mode" : "Basic Mode"}
            </Typography>
            <Autocomplete
              options={datasets}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => setSelectedDataset(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select dataset"
                  placeholder="Datsets"
                />
              )}
              sx={{
                minWidth: 200,
                ml: 2,
                marginBottom: 1,
              }}
            />

            <Select
              value={openSearchIndex}
              onChange={(e) => {
                console.log(e.target);
                setOpenSearchIndex(e.target.value);
                // Find Number of records
                const index = openSearchIndexes.find(
                  (index) => index.value === e.target.value
                );
                console.log(index);
                setNumRecords(index.numRecords);
              }}
              disabled={trainingMode}
              sx={{ marginBottom: 1, marginLeft: 2 }}
            >
              {openSearchIndexes.map((index) => (
                <MenuItem value={index.value} key={index.value.toString()}>
                  {index.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            color="success"
            onClick={handleNext}
            sx={{ color: theme.palette.primary[100] }}
            disabled={dataLoading || pagesWithoutTag == 3}
          >
            Next Page
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
            }}
            onClick={() => {
              if (Object.keys(selectedGarments).length > 0) {
                handleSave();
              } else {
                handleSkip();
              }
            }}
            disabled={dataLoading || pagesWithoutTag < 3}
          >
            New Garment
          </Button>
        </Grid>

        <Grid item xs={9} container spacing={0.5}>
          {garments.map((garment, index) => renderGarment(garment, index))}
        </Grid>

        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              pl: 2,
            }}
          >
            <SeedGarment garment={seedGarment} />
            <Typography variant="body_sm">
              Session Count: {totalAggregations}
            </Typography>
            <Typography variant="body_sm">
              Currently Selected: {Object.keys(selectedGarments).length}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AggregationTool;
