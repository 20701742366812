import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Container,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";

function OnboardingScreen() {
  // State for form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  const fetchUserGroups = async () => {
    let headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/admin/fetch-user-groups`, headers)
      .then((res) => {
        setUserGroups(res.data.data);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  const isValidEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (disabled) return;

    // Construct user data object
    const userData = {
      name,
      email,
      userGroup,
    };

    // TODO: Implement user creation logic, e.g., calling a backend API
    let headers = await getHeaders();

    axios
      .post(`${BASE_URL}tools/admin/create-user`, userData, headers)
      .then((res) => {
        // Reset form fields (optional)
        setName("");
        setEmail("");
        setUserGroup("");
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };
  useEffect(() => {
    if (!isValidEmail(email) || !name || !userGroup) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [email, name, userGroup]);

  useEffect(() => {
    fetchUserGroups();
  }, [userGroups]);

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{
          padding: 2,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          gap: 1, // Add some space between form elements
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h2">New User</Typography>

        <TextField
          label="Name"
          id="name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <TextField
          label="Email"
          variant="outlined"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
        />

        <FormControl fullWidth>
          <InputLabel id="user-group-label">User Group</InputLabel>
          <Select
            labelId="user-group-label" // Make sure this matches the id of the InputLabel
            id="user-group-select" // Adding an id here for the Select
            value={userGroup}
            label="User Group" // This is important for MUI v5 for accessibility
            onChange={(e) => setUserGroup(e.target.value)}
          >
            {userGroups.map((group, index) => (
              <MenuItem key={group.label} value={group.value}>
                {group.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" variant="angular" disabled={disabled}>
          Create User
        </Button>
        <Typography variant="error" color="error">
          {error}
        </Typography>
      </Box>
    </Container>
  );
}

export default OnboardingScreen;
