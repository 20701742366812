import React from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import GarmentProductDetailsGridCard from "./GarmentProductDetailsGridCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function GarmentProductDetailCard({
  garmentId,
  garmentData,
  priceData,
  ...props
}) {
  const garmentBrandTitle = garmentData ? garmentData.brand : null;
  const garmentType = garmentData ? garmentData.type : null;
  const garmentDescription = garmentData ? garmentData.description : null;
  const garmentTitle = garmentData ? garmentData.title : null;

  const theme = useTheme();

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: 1,
              marginLeft: -0.25,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.primary[600],
              }}
            >
              {garmentBrandTitle}
            </Typography>

            <Typography
              variant="h3"
              sx={{
                ml: 2,
                color: theme.palette.primary[600],
              }}
            >
              {garmentType}
            </Typography>
          </Box>
          <Typography
            variant="h3"
            sx={{ marginTop: 2, marginLeft: -0.25, mb: 1 }}
          >
            {garmentTitle}
          </Typography>
          {garmentDescription ? (
            <Typography variant="body_md">{garmentDescription}</Typography>
          ) : (
            <Typography sx={{ mt: 1, color: theme.palette.primary[400] }}>
              No description available for this garment.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={8} lg={12}>
          <GarmentProductDetailsGridCard
            garmentId={garmentId}
            garmentData={garmentData}
            priceData={priceData}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default GarmentProductDetailCard;
