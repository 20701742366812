// src/router/MainRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import GarmentDashboard from "../screens/admin/analytics/GarmentDashboard";

const AnalyticsRoutes = () => {
  return (
    <Routes>
      <Route path="/garment/:id" element={<GarmentDashboard />} />
    </Routes>
  );
};

export default AnalyticsRoutes;
