import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { getCurrentTimestamp } from "../../../utils/dateUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "50vh",
  overflow: "scroll",
};

const MassImageApprovalsFrame = ({
  garments,
  rejectedTags,
  setRejectedTags,
  disabled = false,
  handleControlClick = () => {},
  reject = true,
  preselectAll=false,
  preDelectAll=false,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedGarment, setSelectedGarment] = useState(null);
  const [additionalData, setAdditionalData] = useState([]);

  const handleOpen = (garment) => {
    setSelectedGarment(garment);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    
  }, [preselectAll, preDelectAll])

  return (
    <div>
      <Grid container sx={{mx: -1, width: "100vw"}}>
        {garments.map((garment) => {
          const isSelected = rejectedTags.some(
            (tag) => tag.id === garment.garment_id
          );
          return (
            <Grid
              item
              xs={5}
              sm={2}
              md={2}
              key={garment.garment_id}
              onClick={(e) => {
                if ((e.ctrlKey || e.metaKey) && e.shiftKey == false) {
                  // Your specific function for control-click
                  handleControlClick(garment.garment_id);
                } else {
                  if (disabled) {
                    return;
                  }
                  if (isSelected) {
                    setRejectedTags(
                      rejectedTags.filter(
                        (tag) => tag.id !== garment.garment_id
                      )
                    );
                  } else {
                    setRejectedTags([
                      ...rejectedTags,
                      {
                        id: garment.garment_id,
                        proposed_timestamp: getCurrentTimestamp(),
                      },
                    ]);
                  }
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                handleOpen(garment);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",

                border: isSelected
                  ? reject
                    ? "3px solid red"
                    : "3px solid green"
                  : "1px solid black",
                m: 1,
                maxHeight: "200px",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <Grid container spacing={2} sx={{
                height: "100%", 
                paddingLeft: 1,
                paddingRight: 1,
                marginBottom: 0,
                overflowY: garment?.images.length < 3 ? "hidden" : "scroll",
                scrollbarWidth: "none", // Hide the scrollbar for firefox
                '&::-webkit-scrollbar': {
                    display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                },
                '&-ms-overflow-style:': {
                    display: 'none', // Hide the scrollbar for IE
                },
              }}>
                {garment?.images.length > 1 
                  ? garment?.images.map((image, index) => (
                  <Grid item xs={6}>
                    <img
                      key={index}
                      src={image}
                      alt={`Garment ${index}`}
                      style={{
                        height: "100%", 
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>))
                  : <img
                    src={garment?.images[0]}
                    alt={garment?.images[0]}
                    style={{ height: "100%", objectFit: "contain" }}
                  />
                }
              </Grid>
              
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(255,255,255,0.5)",
                  p: 1,
                }}
              >
                <Typography
                  variant="h8"
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  <Typography
                    variant="h8"
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    {garment.BRAND}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "rgba(255,255,255,0.5)",
                  p: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  ID:{garment.garment_id}
                </Typography>
                {garment.confidence ? (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "11px",
                    }}
                  >
                    (x1000) {Math.round(garment.confidence * 1000)}%
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container spacing={1} sx={style}>
          {selectedGarment?.images.map((image, index) => (
            <Grid item xs={6}>
              <img
                key={index}
                src={image}
                alt={`Garment Image ${index}`}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </Grid>
          ))}
        </Grid>
      </Modal>
    </div>
  );
};

export default MassImageApprovalsFrame;
