import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import { APP_BAR_HEIGHT } from "../../layout/AppBar/AppBarComponent";
import {
    Autocomplete,
    Avatar,
    Box,
    TextField,
    Typography,
    Button, 
    Tooltip, 
    useTheme
  } from "@mui/material";
import CopyGarmentsToolbar from "../../common/toolbars/CopyGarmentsToolbar";
import CopyToDatasetDialog from "../../features/DatasetManagement/CopyToDatasetDialog";
import { styled } from "@mui/material/styles";

const styles = {
    height: "100%",
    width: "100%",
}

const StyledDataGrid = ({columns, 
    rows, 
    rowCount=null,
    setPaginationModel=null,
    paginationModel=null,
    selectedRows=[],
    getRowId=(row)=>row.id,
    pagination=true,
    paginationMode="server",
    checkboxSelection=false,
    copyGarments=false,
    keepNonExistentRowsSelected=true,
    selectionCallback=(newSelection)=>{},  
    components={},
    componentsProps={},
    onCopyButtonClick=()=>{},
    sx,
    ...extraProps}) => {

    const theme = useTheme();    
    const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);

    const handleCopyButtonClick = () => {
        setIsCopyDialogOpen(true);
    };

    const handleCopyDialogClose = () => {
        setIsCopyDialogOpen(false);
    };

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${3}rem)`
            }}>
            <CopyToDatasetDialog
                open={isCopyDialogOpen}
                onClose={handleCopyDialogClose}
                garments={selectedRows}
            />
            
            <DataGridPro
                rows={rows}
                columns={columns}
                getRowId={getRowId}
                pagination={pagination}
                paginationModel={paginationModel}
                rowCount={rowCount}
                paginationMode={paginationMode}
                onPaginationModelChange={setPaginationModel}
                checkboxSelection={checkboxSelection}
                copyGarments={copyGarments}
                keepNonExistentRowsSelected={keepNonExistentRowsSelected}
                onRowSelectionModelChange={(newSelection) => {
                    selectionCallback(newSelection);
                }}
                sx={{
                    ...sx,
                    ...styles,
                    ...(copyGarments && {marginTop: -2})
                }}
                components={{
                    ...(checkboxSelection && copyGarments ? {
                        Toolbar: CopyGarmentsToolbar
                    } : {}),
                    ...components
                }}
                componentsProps={{
                    ...(checkboxSelection && copyGarments ? {
                        toolbar: {
                            onCopy: () => {
                                handleCopyButtonClick();
                                onCopyButtonClick();
                            },
                        }
                    } : {}),
                    ...componentsProps
                }}
                {...extraProps} //TODOWP check this
            />
        </Box>
    );
  }

export default StyledDataGrid; 