import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { getHeaders } from "../../../utils/apiUtils";
import { BASE_URL } from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import AddToGroupDialog from "../../../components/features/UserGroups/AddToGroupDialog";
import CustomToolbar from "../../../components/common/toolbars/UserGridCustomToolbar";
import { useAttributeType } from "../../../contexts/AttributeTypeContext";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import {
  AnalyticsButton,
  EditButton,
  ViewButton,
} from "../../../components/ui/buttons/TableButtons";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";

const defaultDataset = {
  id: "all",
  name: "All Data",
};

function DatasetDashboard() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [datasets, setDatasets] = useState([]);

  // State for handling dialog open/close

  useEffect(() => {
    const fetchDatasets = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/datasets/listall`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setDatasets([defaultDataset, ...res.data.data]);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchDatasets();
  }, []);

  const columns = [
    { field: "id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <EditButton onEdit={() => navigate(`create/${params.row.id}`)} />
      ),
    },
    {
      field: "view",
      headerName: "View Garments",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <ViewButton action={() => navigate(`dashboard/${params.row.id}`)} /> //OLD: navigate(`garments/${params.row.id}`)
      ),
    },
    {
      field: "analytics",
      headerName: "View Analytics",
      flex: 1,
      editable: false,

      renderCell: (params) => (
        <AnalyticsButton
          action={() => navigate(`garments/analytics/${params.row.id}`)}
          disabled={params.row.id === "all" ? true : false}
        />
      ),
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h2" sx={{ mb: 1, mt: 1 }}>
          Datasets Dashboard
        </Typography>
        <GroupConfigurationNavigationWidget
          new_button_text={`New Dataset`}
          show_groups={false}
        />
      </Box>

      <StyledDataGrid
        rows={datasets}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
      />
    </Box>
  );
}

export default DatasetDashboard;
