import React from "react";
import { Box, Typography, Container, useTheme, Button } from "@mui/material";
import ChecklistIcon from "@mui/icons-material/Checklist";
const TakeActionScreenCover = () => {
  const { palette } = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          border: `3px solid ${palette.primary[600]}`,
        }}
      >
        <ChecklistIcon
          sx={{ fontSize: 60, mb: 2, color: palette.primary[600] }}
        />
        <Typography variant="h4" component="div" gutterBottom>
          Make Selection
        </Typography>
        <Typography variant="body1">
          you must make a selection to view data
        </Typography>
      </Box>
    </Container>
  );
};

export default TakeActionScreenCover;
