import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import StyledButton from "../../../components/ui/buttons/StyledButton";
const getFriendlyName = (toolType) => {
  switch (toolType) {
    case "STYLE":
      return "Style";
    case "TYPE":
      return "Type";
    case "STYLE_MAGNETS":
      return "Style";
    default:
      return "Garment Attribute";
  }
};
function MultiGarmentAttributeTool() {
  const {
    inputFilters,
    jobDetails,
    toolType,
    filterHardness,
    datasets,
    jobDataLoaded,
    shownAttributes,
  } = useToolContext();
  const { palette } = useTheme();

  const [garments, setGarments] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedTag, setSelectedTag] = useState(null);
  const [loading, setLoading] = useState(true);

  const { trackEvent } = useAmpltiudeEventTrackingContext();
  const [tagSet, setTagSet] = useState([]);
  const friendlyName = getFriendlyName(toolType);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [jobComplete, setJobComplete] = useState(false);
  const [rejectItems, setRejectItems] = useState(false);

  //Axios Calls
  const fetchTagSet = async () => {
    const headers = await getHeaders();
    axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}`,
        headers
      )
      .then((res) => {
        setTagSet(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchNextItem = async () => {
    setLoading(true);

    const headers = await getHeaders();
    const submissionData = {
      filters: inputFilters,
      filterHardness: filterHardness,
      job_id: jobDetails.job_id,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      datasets: datasets,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      shownAttributes: shownAttributes,
      proposalId: selectedTag,
    };
    await axios
      .post(
        `${BASE_URL}tools/summon/next-item/basic/proposal-ranked`,
        submissionData,
        headers
      )
      .then((res) => {
        setPresentedTimeStamp(getCurrentTimestamp());
        setGarments(res.data.data);
      })
      .catch((err) => {
        setJobComplete(true);
        setGarments([]);
      });
    setLoading(false);
  };

  const submitItems = async () => {
    const headers = await getHeaders();
    setLoading(true);
    const rejectedIds = selectedTags.map((t) => t.id);
    let approved;
    let rejected;
    if (rejectItems) {
      approved = garments
        .filter((garment) => !rejectedIds.includes(garment.garment_id))
        .map((garment) => {
          return { id: garment.garment_id, linked_id: garment.linked_id };
        });
      rejected = garments
        .filter((garment) => rejectedIds.includes(garment.garment_id))
        .map((garment) => {
          return { id: garment.garment_id, linked_id: garment.linked_id };
        });
    } else {
      approved = garments
        .filter((garment) => rejectedIds.includes(garment.garment_id))
        .map((garment) => {
          return { id: garment.garment_id, linked_id: garment.linked_id };
        });
      rejected = garments
        .filter((garment) => !rejectedIds.includes(garment.garment_id))
        .map((garment) => {
          return { id: garment.garment_id, linked_id: garment.linked_id };
        });
    }

    const data = {
      approved: approved,
      rejected: rejected,
      proposal_value_id: selectedTag,
      jobId: jobDetails.job_id,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      toolType: toolType,
      presentedTimeStamp: presentedTimeStamp,
    };
    await axios
      .post(`${BASE_URL}tools/classify/basic/proposal-ranked`, data, headers)
      .then((res) => {
        fetchNextItem();
        setSelectedTags([]);

        trackEvent("ProposalQA", {
          approvedGarmentIds: approved,
          rejectedGarmentIds: rejected,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: toolType,
          toolName: "MultiGarmentAttributeTool",
          confidence: jobDetails.qa_confidence,
          skipped: approved.length === 0,
          qa: true,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  };
  const handleCheckboxChange = (e) => {
    setRejectItems(e.target.checked);
  };

  //Use Effects
  useEffect(() => {
    if (jobDataLoaded) {
      fetchTagSet();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    if (selectedTag) {
      fetchNextItem();
    }
  }, [selectedTag]);

  //Handlers
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoadingOverlay open={loading} />
      <Typography
        variant="h4"
        color={"primary.800"}
        sx={{
          flex: 1,
          mb: 2,
        }}
      >
        Multi Garment {friendlyName} Tagging
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: 300 }}>
          <Typography id="tag-selector" variant="h8" gutterBottom>
            Selected Tag:
          </Typography>
          <Select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            sx={{
              width: "100%",
              mt: 1,
            }}
          >
            <MenuItem key={null} value={null}>
              Select Tag
            </MenuItem>
            {tagSet.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={rejectItems}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Reject Items"
          />
        </Box>
        {/* ToggleButton to reject all garments */}

        <Box>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => submitItems()}
          >
            SUBMIT
          </StyledButton>
        </Box>
      </Box>
      {selectedTag ? (
        (garments.length === 0) & !loading ? (
          <NoDataScreenCover showBackButton={false} />
        ) : (
          <MassImageApprovalsFrame
            tags={tagSet}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            garments={garments}
            rejectedTags={selectedTags}
            setRejectedTags={setSelectedTags}
            shownAttributes={["confidence"]}
            reject={rejectItems}
          />
        )
      ) : (
        <TakeActionScreenCover />
      )}
    </Box>
  );
}

function WrappedMultiGarmentTool() {
  //Job Pipeline ID
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <MultiGarmentAttributeTool />
    </ToolProvider>
  );
}

export default WrappedMultiGarmentTool;
