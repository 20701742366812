// SearchableMultiSelect.js
import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  TextField,
  FormControl,
  Autocomplete,
  Box,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";

const StatusMultiSelect = ({
  typeMapping,
  selected,
  onSelectedChange,
  label,
}) => {
  const [options, setOptions] = useState([
    { name: "APPROVED", value: "APPROVED" },
    { name: "PENDING", value: "PENDING" },
    { name: "DECLINED", value: "DECLINED" },
  ]);
  const componentRef = useRef(); // Create a ref for the component

  // Function to fetch filtered options from the database

  return (
    <Box ref={componentRef}>
      <FormControl component="fieldset" variant="standard">
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          )}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              margin="normal"
            />
          )}
          value={selected}
          onChange={(event, newValue) => {
            onSelectedChange(newValue);
          }}
        />
      </FormControl>
    </Box>
  );
};

export default StatusMultiSelect;
