import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function PineconeIndexDropdown({ setIndex, index, setMetaData, disabled }) {
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);
  async function getIndexes() {
    const headers = await getHeaders();
    await axios
      .get(`${BASE_URL}tools/aggregation/list-indexes`, headers)
      .then((res) => {
        setOpenSearchIndexes(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  }

  useEffect(() => {
    getIndexes();
  }, []);

  return (
    <Select
      value={index}
      onChange={(e) => {
        setIndex(e.target.value);
        // Find Number of records
        const index = openSearchIndexes.find(
          (index) => index.value === e.target.value
        );
        if (typeof setMetaData == "function") {
          setMetaData(index);
        }
      }}
      disabled={disabled}
    >
      {openSearchIndexes.map((index) => (
        <MenuItem value={index.value} key={index.value.toString()}>
          {index.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default PineconeIndexDropdown;
