import React from "react";
import { Box, Typography } from "@mui/material";
import ClickableImage from "../../layout/Interactive/ClickableImage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function SeedGarment({ garment }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Carousel showThumbs={false} showStatus={false}>
        {garment?.images.map((image, index) => (
          <Box
            key={index + 1}
            sx={{
              borderRadius: 0.1,
              width: "100%",
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <ClickableImage image={image} />
          </Box>
        ))}
      </Carousel>
      <Typography variant="h4">{garment?.title}</Typography>
    </Box>
  );
}

export default SeedGarment;
