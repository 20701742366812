import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import { StyledButton } from "../../../components/ui/buttons/StyledButton";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";

const limit = 15;

export default function AggregationQATool() {
  const [loading, setLoading] = useState(false);
  const [garments, setGarments] = useState([]);
  const [rejectedTags, setRejectedTags] = useState([]);
  const [page, setPage] = useState(1);
  const [pendingRequest, setPendingRequest] = useState(true);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [groupRepresentativeChanged, setGroupRepresentativeChanged] =
    useState(false);

  const theme = useTheme();
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [openSearchIndex, setOpenSearchIndex] = useState(null);

  const [datasets, setDatasets] = useState([]);
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);

  const [largestGroup, setLargestGroup] = useState({
    garment_count: 0,
    group_uuid: null,
  });
  const [newGroup, setNewGroup] = useState({
    garment_count: 0,
    group_uuid: null,
  });
  const [groupRepresentative, setGroupRepresentative] = useState(null);
  const [selectedGroupRepresentative, setSelectedGroupRepresentative] =
    useState(null);
  const [groupRepresentativeModalOpen, setGroupRepresentativeModalOpen] =
    useState(false);

  async function fetchIndexes() {
    const headers = await getHeaders();

    await axios
      .get(`${BASE_URL}tools/aggregation/list-indexes`, headers)
      .then((res) => {
        console.log(res.data.data);
        setOpenSearchIndexes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  }
  async function fetchDatasets() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );
      setDatasets(res.data.data);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  async function fetchLargestGroup() {
    const headers = await getHeaders();
    setPendingRequest(true);

    try {
      const res = await axios.post(
        `${BASE_URL}tools/aggregation/fetch-largest-group`,
        { datasetID: selectedDataset?.id, index: openSearchIndex },
        headers
      );

      setLargestGroup(res.data.data);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  async function fetchGarments() {
    const headers = await getHeaders();
    if (!largestGroup?.group_uuid) {
      return;
    }
    setPendingRequest(true);
    try {
      const res = await axios.post(
        `${BASE_URL}tools/summon/next-item/qa/aggregation`,
        {
          group_uuid: largestGroup.group_uuid,
          offset: (page - 1) * limit,
          limit: limit,
        },
        headers
      );
      setGarments(res.data.data);
      setPendingRequest(false);
      setPresentedTimeStamp(getCurrentTimestamp());
    } catch (error) {
      setPendingRequest(false);
      showAlert(error);
    }
  }
  const handleNextPage = () => {
    setPage(page + 1);
    handleSubmit();
  };
  const handleSubmit = async () => {
    const combinedArray = rejectedTags
      .map((tag) => {
        const garment = garments.find((g) => g.garment_id === tag.id);
        if (garment) {
          return {
            ...tag,
            ...garment,
            presented_timestamp: presentedTimeStamp,
          }; // Merge the objects if there's a match
        }
        return null;
      })
      .filter((x) => x);
    if (groupRepresentativeChanged) {
      combinedArray.push({
        ...groupRepresentative,
        is_group_representative: true,
        presented_timestamp: presentedTimeStamp,
      });
    }

    const params = {
      selected_garments: combinedArray,
      new_group_uuid: newGroup.group_uuid,
    };
    const headers = await getHeaders();
    const res = await axios
      .post(`${BASE_URL}tools/classify/qa/aggregation`, params, headers)
      .then(() => {
        setRejectedTags([]);
        setNewGroup({
          ...newGroup,
          garment_count: newGroup.garment_count + combinedArray.length,
        });
        setGroupRepresentativeChanged(false);

        trackEvent("AggregationQA", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: groupRepresentative["garment_id"],
          aggregations: newGroup.garment_count,
          totalAggregations: newGroup.garment_count + combinedArray.length,
          selectedGarmentIds: Object.keys(combinedArray),
          jobId: 17,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationQATool",
          qa: true,
          skipped: combinedArray.length === 0, //"Skipped" is no selected garments
        });
      })
      .catch((err) => {
        showAlert(err);
      });
  };
  const handleResetNewGroup = () => {
    const newUUID = uuidv4();
    setNewGroup({
      group_uuid: newUUID,
      garment_count: 0,
    });
  };

  useEffect(() => {
    if (openSearchIndex) {
      setGarments([]);
      setGroupRepresentative(null);
      setSelectedGroupRepresentative(null);
      fetchLargestGroup();
    }
  }, [selectedDataset, openSearchIndex]);

  useEffect(() => {
    setPage(1);
    handleResetNewGroup();
  }, [largestGroup]);

  useEffect(() => {
    setPendingRequest(true);
    fetchGarments();
  }, [page, largestGroup]);

  useEffect(() => {
    fetchDatasets();
    fetchIndexes();
    if (!newGroup.group_uuid) {
      handleResetNewGroup();
    }
  }, []);

  const handleControlClick = (garmentId) => {
    // Find Garment
    const [tempGarment] = garments.filter((g) => g.garment_id === garmentId);
    setSelectedGroupRepresentative({
      ...tempGarment,
      proposed_timestamp: getCurrentTimestamp(),
    });
    setGroupRepresentativeModalOpen(true);
  };
  
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomModal
        showModal={groupRepresentativeModalOpen}
        title={"Set Group Representative"}
        body={
          " Are you sure you would like to chnage the group representative, this cannot be undone."
        }
        onDismissTitle="Return"
        onConfirmTitle="Confirm"
        onConfirm={() => {
          setGroupRepresentative(selectedGroupRepresentative);
          setGroupRepresentativeModalOpen(false);
          setSelectedGroupRepresentative(null);
          setGroupRepresentativeChanged(true);

          trackEvent("AggregationQASetGroupRepresentative", {
            index: openSearchIndex,
            datasetId: selectedDataset?.id,
            garmentId: selectedGroupRepresentative["garment_id"],
            proposedTimestamp:
              selectedGroupRepresentative["proposed_timestamp"],
            jobId: 17,
            jobName: "aggregation",
            toolType: "AGGREGATION",
            toolName: "AggregationQATool",
            qa: true,
          });
        }}
        onDismiss={() => {
          setSelectedGroupRepresentative(null);
          setGroupRepresentativeModalOpen(false);
        }}
      />
      <LoadingOverlay open={loading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Typography variant="h2">Aggregation Tool</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 1,
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            options={datasets}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => setSelectedDataset(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select dataset"
                placeholder="Datsets"
              />
            )}
            sx={{
              minWidth: 200,
            }}
          />

          <Select
            value={openSearchIndex}
            sx={{
              minWidth: 200,
              ml: 2,
            }}
            onChange={(e) => {
              console.log(e.target);
              setOpenSearchIndex(e.target.value);
              // Find Number of records
              const index = openSearchIndexes.find(
                (index) => index.value === e.target.value
              );
              console.log(index);
            }}
          >
            {openSearchIndexes.map((index) => (
              <MenuItem value={index.value}>{index.label}</MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>Existing Group</Typography>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary[500]}`,
                backgroundColor: theme.palette.primary[200],
                p: 1.25,
                display: "flex",
                flexDirection: "row",
                borderRadius: 1,
              }}
            >
              {largestGroup ? (
                <>
                  <Typography
                    sx={{
                      mr: 2,
                    }}
                    noWrap
                  >
                    Group UUID: {largestGroup.group_uuid}
                  </Typography>
                  <Typography
                    noWrap
                    sx={{
                      mr: 2,
                    }}
                  >
                    Group Size: {largestGroup.garment_count}
                  </Typography>
                  <Typography noWrap>
                    Brand Hits: {largestGroup.brand_hit_count}
                  </Typography>
                </>
              ) : (
                <Typography color="error">
                  No data available for Dataset and Index
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>New Group</Typography>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary[500]}`,
                backgroundColor: theme.palette.primary[200],
                p: 1.25,
                display: "flex",
                flexDirection: "row",
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  mr: 2,
                }}
                noWrap
              >
                Group UUID: {newGroup.group_uuid}
              </Typography>
              <Typography noWrap>
                Group Size: {newGroup.garment_count}
              </Typography>
            </Box>
          </Box>
          <StyledButton
            variant="contained"
            color="primary"
            sx={{ ml: 2, color: theme.palette.primary[100] }}
            disabled={pendingRequest || !groupRepresentative}
            onClick={handleNextPage}
          >
            Next Page
          </StyledButton>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 1,
        }}
      />
      {openSearchIndex ? (
        (garments.length === 0) & !loading ? (
          <NoDataScreenCover showBackButton={false} />
        ) : (
          <Grid container>
            <Grid xs={8}>
              <MassImageApprovalsFrame
                garments={garments}
                rejectedTags={rejectedTags}
                setRejectedTags={setRejectedTags}
                disabled={!groupRepresentative}
                handleControlClick={handleControlClick}
                reject={false}
              />
            </Grid>
            <Grid
              xs={4}
              sx={{
                mt: 1,
              }}
            >
              <Typography variant="h3">Group Representative</Typography>

              <Box
                sx={{
                  border: `1px solid ${theme.palette.primary[400]}`,
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <SeedGarment garment={groupRepresentative} />
              </Box>
            </Grid>
          </Grid>
        )
      ) : (
        <TakeActionScreenCover />
      )}
    </Box>
  );
}
