import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_BAR_HEIGHT } from "../../layout/AppBar/AppBarComponent";
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
  Button,
  Tooltip,
  useTheme,
} from "@mui/material";
import { formatCurrency_GBP, range } from "../../../utils/sharedFunctions";
import {
  getFormattedTimestamp,
  formatTimeToSell,
  getFormattedTimeToSellComponent,
} from "../../../utils/dateUtils";
import { styled } from "@mui/material/styles";
import StyledDataGrid from "./StyledDataGrid";
import FormattedCurrencyTypography from "../typography/FormattedCurrencyTypography";
import ClickableImage from "../../layout/Interactive/ClickableImage";

const SingleGarmentDataGrid = ({ ...props }) => {
  const theme = useTheme();

  const getConditionComponent = (conditionLabel) => {
    switch (conditionLabel) {
      case "is_gently_used":
        return <Typography style={{}}>{conditionLabel}</Typography>;
      default:
        return <Typography style={{ color: "red" }}>{"N/A"}</Typography>;
    }
  };

  const garmentDataColumns = [
    {
      field: "uuid",
      headerName: "IMAGE",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const uuid_public_id = params.value;
        return (
          <ClickableImage
            image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${uuid_public_id}-1.jpg`}
            alt={params.row.name}
            variant="square"
            sxImg={{ height: 96, width: 96, paddingY: 2 }}
          />
        );
      },
      headerClassName: "custom-data-grid-header",
    },
    {
      field: "sold_price_in_gbp",
      headerName: "SALE PRICE",
      flex: 1,
      renderCell: (params) => (
        <FormattedCurrencyTypography value={params.value} nullIfZero={true} />
      ),
    },
    {
      field: "listed_price_in_gbp",
      headerName: "LISTING PRICE",
      flex: 1,
      renderCell: (params) => (
        <FormattedCurrencyTypography value={params.value} nullIfZero={true} />
      ),
    },
    {
      field: "sold",
      headerName: "SOLD",
      flex: 1,
      valueGetter: (params) => params.row.sold_price_in_gbp,
      renderCell: (params) => (
        <Typography>
          {params.value !== null && params.value !== 0 ? `Yes` : `No`}
        </Typography>
      ),
    },
    {
      field: "vendor",
      headerName: "VENDOR",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
    {
      field: "size",
      headerName: "SIZE",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "condition",
      headerName: "CONDITION",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "time_to_sell",
      headerName: "TIME TO SELL",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value
            ? getFormattedTimeToSellComponent(params.value, {
                color: `${theme.palette.primary[800]} !important`,
              })
            : "-"}
        </Typography>
      ),
    },
    {
      field: "listed_timestamp",
      headerName: "LISTED DATE",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? getFormattedTimestamp(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "sold_timestamp",
      headerName: "SOLD DATE",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value != null ? getFormattedTimestamp(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "location",
      headerName: "LOCATION",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value ? "inherit" : theme.palette.primary[400],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value || "-"}
        </Typography>
      ),
    },
  ];

  return (
    <Box sx={{ mt: 1 }}>
      <StyledDataGrid columns={garmentDataColumns} {...props} />
    </Box>
  );
};

export const StyledSingleGarmentDataGrid = styled(SingleGarmentDataGrid)(
  ({ theme, variant, color }) => ({})
);

export default SingleGarmentDataGrid;
