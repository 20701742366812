import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ClickableImage from "../../../components/layout/Interactive/ClickableImage";

function GarmentPanel({
  garment,
  selectedGarments,
  ignoredGarments,
  setIgnoredGarments,
  setSelectedGarments,
  index,
}) {
  const { uuid_public_id, score, garment_id } = garment;
  const isSelected = selectedGarments.hasOwnProperty(garment_id);
  const onImageClick = () => toggleSelection(garment_id, score);

  const toggleSelection = (garmentId, garmentScore) => {
    setSelectedGarments((prevSelected) => {
      const currentTimestamp = new Date().toISOString();
      if (prevSelected.hasOwnProperty(garmentId)) {
        // If the garmentId is already a key, remove it and add to ignored
        const updatedSelection = { ...prevSelected };
        delete updatedSelection[garmentId];

        setIgnoredGarments((prevIgnored) => ({
          ...prevIgnored,
          [garmentId]: {
            score: garmentScore,
            timestamp: currentTimestamp,
            rank: index,
          },
        }));

        return updatedSelection;
      } else {
        // Otherwise, add the garmentId to selected and remove from ignored
        const updatedIgnored = { ...ignoredGarments };
        delete updatedIgnored[garmentId];

        setIgnoredGarments(updatedIgnored);

        return {
          ...prevSelected,
          [garmentId]: {
            score: garmentScore,
            timestamp: currentTimestamp,
            rank: index,
          },
        };
      }
    });
  };
  return (
    <Grid item xs={3} sm={2}>
      <Box
        sx={{
          height: '22.5vh', // Fixed height
          backgroundColor: isSelected ? "primary.main" : "primary.light",
          border: "1px solid black",
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="body_xs" style={{ display: "block" }}>
          Score: {score}
        </Typography>
        <Typography variant="body_xs" style={{ display: "block" }}>
          ID: {garment_id}
        </Typography>
        <Box
          onClick={onImageClick}
          sx={{
            borderRadius: 0.1,
            width: "100%",
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden' 
          }}
        >
          <ClickableImage
            image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${uuid_public_id}-1.jpg`}
          />
        </Box>
      </Box>
    </Grid>
  );
}

export default GarmentPanel;
